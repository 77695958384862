import moment from "moment/moment"
import { useState } from "react"
import { useNavigate } from "react-router-dom";

export default function NotificationComponent(props){
    const navigate = useNavigate();

    const [notifications, setNotifications] = useState([])
    const [notificationsCount, setNotificationsCount] = useState(notifications?.length);
    // const [notifications, setNotifications] = useState([
    //     {
    //         id: 1,
    //         message: 'Lorem ipsum dolor sit amet, consectetur adipiscing',
    //         read: false,
    //         createdAt: new Date()
    //     },
    //     {
    //         id: 2,
    //         message: 'Lorem ipsum dolor sit amet, consectetur adipiscing',
    //         read: true,
    //         createdAt: new Date()
    //     },
    //     {
    //         id: 3,
    //         message: 'Lorem ipsum dolor sit amet, consectetur adipiscing',
    //         read: true,
    //         createdAt: new Date()
    //     },
    //     {
    //         id: 4,
    //         message: 'Lorem ipsum dolor sit amet, consectetur adipiscing',
    //         read: true,
    //         createdAt: new Date()
    //     }
    // ])

    const navigateTo = (page) => {
        props.toggle();
        navigate(page)
    }

    return (
        <div 
            onClick={props.toggle}
            className={`absolute bg-transparent rounded-lg w-screen h-screen left-0 top-0 z-10 justify-end flex ${props?.visible ? 'visible': 'hidden'}`}>
            <div 
                onClick={(event) => event.stopPropagation()}
                className="w-1/4 bg-white rounded-xl absolute top-20 right-14 p-5">
                <div class="absolute w-7 h-7 bg-white right-9 -top-1 -mt-2 mr-4 transform rotate-45" />
                <h1 className="text-[#161616] text-xl font-bold">Notifications</h1>
                <div className="flex flex-col h-full">
                    <div className="pt-5 px-5 flex">
                        <div className="border-b-4 flex gap-2 pb-2 border-primary items-center">
                            <h3>All</h3>
                            <div className="bg-blue text-white px-2 rounded-full">
                                <span className="text-xs">{notificationsCount}</span>
                            </div>
                        </div>
                    </div>
                    <hr className="border-[#E7E7E7]"/>
                    <div className={`${notifications?.length === 0 ? 'visible': 'hidden'} flex items-center justify-center py-5`}>
                        <h3 className="text-brownGray">No notifications</h3>
                    </div>
                    <ul className={`${notifications?.length === 0 ? 'hidden': 'visible'} flex-1 flex flex-col gap-2 py-8`}>
                        {notifications?.map((item) => (
                            <li 
                                key={item.id?.toString()} 
                                className={`flex gap-4 items-center p-4 rounded-lg  ${item.read ? '' : 'bg-[#F6F7F9]'} items-center`}>
                                <div className="min-h-10 min-w-10 bg-primary text-white font-normal rounded-full justify-center items-center flex">
                                    <h3 className="text-base">S</h3>
                                </div>
                                <div className="text-brownGray flex flex-col gap-1">
                                    <p className="text-sm">{item?.message}</p>
                                    <span className="text-xs">{moment(item.createdAt).fromNow()}</span>
                                </div>
                                <div className={`bg-blue min-h-3 min-w-3 rounded-full ${item.read ? 'hidden': 'visible'}`} />
                            </li>
                        ))}
                    </ul>
                    <div className={`${notifications?.length === 0 ? 'hidden': 'visible'} flex justify-center items-center h-10`}>
                        <button 
                            onClick={navigateTo.bind(this, '/dashboard/notifications')}
                            className="text-[#161616] font-semibold text-sm">
                            View All 
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

NotificationComponent.defaultProps = {
    visible: false
}