import { Dialog } from "primereact/dialog";
import {MdInfoOutline} from 'react-icons/md';
import { useMonitorStatus } from "../../contextProviders/MonitorContext";

export default function MultiMonitorDetectedPopup(){
    const {hasMultipleMonitors} = useMonitorStatus();

    if(hasMultipleMonitors){
        return (
            <Dialog 
                visible={true}
                closable={false}
                draggable={false}
                header={
                    <div className='flex gap-5 items-center text-primary justify-between'>
                        <h1>Multiple monitors detected</h1>
                        <MdInfoOutline size={36}/>
                    </div>
                }
            >
                <div className='flex flex-col justify-center items-center gap-10'>
                    <h4>Multiple monitors detected. Please disconnect additional monitors</h4>
                </div>
            </Dialog>
        )    
    }

    return (
        <div></div>
    )
}