import { Outlet} from "react-router-dom";
import HRSidebar from "../../components/HR/HRSidebar";

export default function HRHome(){

    return (
        <main className="w-screen h-screen flex overflow-hidden">
            <HRSidebar />
            <div style={{ width: `calc(100vw - 240px)` }} className="bg-[#ECF5FA] flex-col h-full overflow-hidden max-sm:!w-full w-full justify-center items-center flex">
                <div className="overflow-y-scroll h-full w-full flex justify-center">
                    <div className="h-full w-full">
                        <Outlet />
                    </div>
                </div>
            </div>
        </main>
    )
}

// JD_Machine Learning Engineer.pdf