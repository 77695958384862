import { useState } from "react";
import { Column } from 'primereact/column';
import { useNavigate } from "react-router-dom"
import { DataTable } from 'primereact/datatable';
import { Paginator } from 'primereact/paginator';
import addIcon from './../../../assets/icons/add.svg'
import { BsThreeDotsVertical } from "react-icons/bs";
import editIcon from './../../../assets/icons/edit.svg';
import deleteIcon from './../../../assets/icons/delete.svg';
import { getPaginationLimit } from "../../../utils/common";
import SearchbarComponent from "../../../components/Common/SearchbarComponent";
import HRRolesDropdownComponent from "../../../components/Common/HRRolesDropdownComponent";
import JobProfileDropdownComponent from "../../../components/Common/JobProfileDropdownComponent";

export default function HRUserManagement(){
    const navigate = useNavigate();
    const [search, setSearch] = useState(null);
    const [selectedHRRole, setSelectedHRRole] = useState(null);
    const [selectedJobProfile, setSelectedJobProfile] = useState(null);

        
    const [first, setFirst] = useState(0);
    const [loading, setLoading] = useState(false);
    const [totalCount, setTotalCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);

    const [rows, setRows] = useState(getPaginationLimit());
    const [limit, setLimit] = useState(getPaginationLimit());
    const [selectedUser, setSelectedUser] = useState(null);

    const [users, setUsers] = useState([
        {
            _id: 1, 
            name: 'Abhishek', 
            role: 'HR admin',
            email: 'abhikam395@gmail.com', 
            mobileNumber: '+91 4542342332'
        },
        {
            _id: 2, 
            name: 'Rohit', 
            role: 'HR',
            email: 'rohit@gmail.com', 
            mobileNumber: '+91 889342332',
        }
    ])

    const onPageChange = (event) => {
        setFirst(event.first);
        setRows(event.rows);
        setCurrentPage(event.page + 1);
    };

    const options = (item) => {
        return (
            <div 
                onClick={(event) => event?.stopPropagation()}
                className='relative'>
                <BsThreeDotsVertical
                    size={20} 
                    color='#666666'
                    className="cursor-pointer"
                    onClick={() => setSelectedUser(item)}
                />
                <div className={`${selectedUser?._id === item?._id ? 'visible' : 'hidden'} rounded-lg shadow shadow-gray bg-white -top-12 right-0 z-20 absolute`}>
                    <ul className="p-2 gap-2 flex flex-col">
                        <li 
                            className='py-2 text-blue flex items-center  gap-2 px-6 rounded cursor-pointer' 
                            style={{backgroundColor: 'rgba(60, 153, 202, .1)'}}>
                            <img 
                                alt='edit-icon'
                                src={editIcon}
                                className='h-4 w-4'
                            />
                            <h4 className='text-sm'>Edit</h4>
                        </li>
                        <li 
                            className='py-2 text-[#FF3B30] flex items-center gap-2 px-6 rounded cursor-pointer' 
                            style={{backgroundColor: 'rgba(255, 59, 48, .1)'}}>
                            <img 
                                alt='edit-icon'
                                src={deleteIcon}
                                className='h-4 w-4'
                            />
                            <h4 className='text-sm'>Delete</h4>
                        </li>
                    </ul>
                </div>
            </div>
        )
    }

    return (
        <div 
            onClick={() => setSelectedUser(null)}
            className="flex flex-col h-full gap-4">
            <div className="flex justify-between items-center">
                <h1 className='text-[#161616] text-xl font-bold'>HR team list</h1>
                <div 
                    onClick={() => navigate('/dashboard/user-management/create')}
                    className="bg-blue cursor-pointer text-white px-4 h-10 rounded font-semibold text-sm flex items-center gap-4">
                    <span>Create HR Login</span>
                    <img 
                        alt='add'
                        src={addIcon}
                        className='h-5 w-5'
                    />
                </div>
            </div>
            <div className='flex gap-4'>
                <SearchbarComponent 
                    label={search}
                    onChange={setSearch}              
                />
                <HRRolesDropdownComponent 
                    label={selectedHRRole}
                    setLabel={setSelectedHRRole}
                />
                <JobProfileDropdownComponent 
                    label={selectedJobProfile}
                    setLabel={setSelectedJobProfile}
                />
            </div>
            <div 
                className="bg-white rounded mt-5">
                <DataTable 
                    value={users}
                    loading={loading}
                    emptyMessage="No Jd available"
                    rowClassName="bg-white">
                    <Column 
                        field="name" 
                        header="Name" 
                        className='text-sm 2xl:text-base'
                        headerClassName='text-sm 2xl:text-base bg-white' 
                    />
                    <Column 
                        field="role" 
                        header="Role" 
                        className='text-sm 2xl:text-base'
                        headerClassName='text-sm 2xl:text-base bg-white' 
                    />
                    <Column 
                        field="email" 
                        header="Email" 
                        className='text-sm 2xl:text-base'
                        headerClassName='text-sm 2xl:text-base bg-white' 
                    />
                    <Column 
                        field="mobileNumber" 
                        header="Mobile Number" 
                        className='text-sm 2xl:text-base'
                        headerClassName='text-sm 2xl:text-base bg-white bg-white' 
                    />
                    <Column 
                        body={options}
                        className='text-sm 2xl:text-base relative'
                        headerClassName='text-sm 2xl:text-base bg-white' 
                    />
                </DataTable>
            </div>
            <Paginator 
                rows={rows}
                first={first}
                totalRecords={50}
                onPageChange={onPageChange}
                className='bg-[#ECF5FA] justify-end mt-5'
            />
        </div>
    )
}