import React, { 
    useEffect, 
    useState, 
} from 'react';
import { CgFileDocument } from "react-icons/cg";
import { Column } from "primereact/column";
import { MdOutlineClear } from "react-icons/md";
import { DataTable } from "primereact/datatable";
import SuccessPopup from '../Common/SuccessPopup';
import { useLocation } from 'react-router-dom';
import LoadingComponent from '../Common/LoadingComponent';
import ResumeService from "./../../services/resume.service";
import { RESUME_STAGES } from "./../../utils/constants";
import ResumeViewerPopup from '../JD/ResumeViewerPopup';

function ResumeSelectPopup(props){

    const {pathname} = useLocation();
    const [resumes, setResumes] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isUploaded, setIsUploaded] = useState(false);
    const [isUploading, setIsUploading] = useState(false);
    const [selectedResume, setSelectedResume] = useState(null);
    const [selectedResumes, setSelectedResumes] = useState(null);
    const jdId = pathname.replace('/dashboard/job-description/', '');
    const [showResumeViewerPopup, setShowResumeViewerPopup] = useState(false);

    const getResumes = async () => {
        try {
            setLoading(true);
            const response = await ResumeService.getResumes();
            if(response.status === 200){
                const {data} = response.data || []; 
                const list = data?.map((item) => ({...item, selected: false}))
                setResumes(list);
            }
        } catch (error) {}
        finally{
            setLoading(false);
        }
    }

    const uploadResumes = async () => {
        try {
            setIsUploading(true);
            const data = {
                jdId, 
                resumeIds: selectedResumes?.map((item) => item?._id)
            }
            const response = await ResumeService.uploadResumes(data)
            setIsUploading(false);
            if(response.status === 201){
                setIsUploaded(true);
                setTimeout(() => {
                    setIsUploaded(false);
                    props.hide();
                    props.reload();
                }, 3000);
            }
        } catch (error) {
            setIsUploading(false);
            setIsUploaded(false);
        }
    }

    useEffect(() => {
        getResumes();
    }, []);

    const renderEmail = ({meta}) => {
        return <span>{meta?.['Email'] || meta?.['email']}</span>;
    }

    const renderJobTitle = ({jdDetails}) => {
        return <span>{jdDetails?.['title'] || jdDetails?.['title']}</span>;
    }

    const renderName = ({meta}) => {
        return <span>{meta?.['Name'] || meta?.['name']}</span>;
    }

    const renderStatus = (props) => {
        const {initialScreening, stage, status, technicalScreening, opsScreening } = props;
        return (
            <div className="flex gap-4 text-sm items-center w-40">
                <span className='text-ellipsis overflow-hidden whitespace-nowrap'>
                    {stage === RESUME_STAGES.INITIAL_SCREENING ? initialScreening?.status : stage === RESUME_STAGES.TECHNICAL_SCREENING ? technicalScreening?.status : stage === RESUME_STAGES.OPS_SCREENING ? opsScreening?.status : status}
                </span>
            </div>
        )
    }

    const options = (item) => {
        return (
            <div 
                className="cursor-pointer"
                onClick={(event) => {
                    event?.stopPropagation();
                    setSelectedResume(item);
                    setShowResumeViewerPopup(true);
                }}>
                <CgFileDocument 
                    size={20}
                />
            </div>
        )
    }

    return (
        <section 
            onClick={(event) => {
                event.stopPropagation();
                props.hide();
            }}
            className="bg-transparent absolute left-0 top-0 z-40 h-screen w-screen flex flex-col px-10 overflow-hidden">
            <div 
                onClick={(event) => { event.stopPropagation() }}
                className='w-[80%] bg-white m-auto my-10 rounded-md overflow-y-scroll'>
                {showResumeViewerPopup && (
                    <ResumeViewerPopup 
                        header={`${selectedResume?.meta?.['Name'] || selectedResume?.meta?.['name']} Resume`}
                        resumeUrl={selectedResume?.resumeUrl}
                        close={() => {
                            setSelectedResume(null);
                            setShowResumeViewerPopup(false)
                        }}
                    />
                )}
                <div className='flex flex-row gap-10 justify-between items-center px-5 pt-3'>
                    <h1 className='font-bold text-primary text-lg'>Select Resumes</h1>
                    <div className='flex justify-end items-center gap-5'>
                        <button 
                            onClick={uploadResumes}
                            className={`${selectedResumes?.length > 0 ? 'visible': 'hidden'} 
                            font-normal px-10 h-10 rounded-md bg-blue text-white`}>Upload</button>
                        <MdOutlineClear 
                            size={24}
                            onClick={props.hide}
                            className='text-primary cursor-pointer'
                        />
                    </div>
                </div>
                <div className="overflow-y-scroll p-5">
                    <DataTable
                        dataKey="_id"
                        editMode="row" 
                        value={resumes}
                        loading={loading}
                        rowClassName="bg-white"
                        selectionMode='checkbox'
                        selection={selectedResumes}
                        className="text-brownGray"
                        emptyMessage="No resume available" 
                        onSelectionChange={(e) => setSelectedResumes(e.value)} >
                        <Column 
                            selectionMode="multiple" 
                            headerStyle={{ width: '3rem' }}
                        />
                        <Column 
                            body={options}
                            className='text-sm 2xl:text-base'
                            headerClassName='text-sm 2xl:text-base bg-white' 
                        />
                        <Column  
                            header="Name" 
                            field="name" 
                            body={renderName}
                            className='text-sm 2xl:text-base'
                            headerClassName='text-sm 2xl:text-base bg-white' 
                        />
                        <Column 
                            header="Email" 
                            field="email" 
                            body={renderEmail}
                            className='text-sm 2xl:text-base'
                            headerClassName='text-sm 2xl:text-base bg-white' 
                        />
                        <Column 
                            header="Job Title" 
                            body={renderJobTitle}
                            className='text-sm 2xl:text-base'
                            headerClassName='text-sm 2xl:text-base bg-white' 
                        />
                        {/* <Column 
                            header="Mobile number" 
                            body={renderMobileNumber}
                            className='text-sm 2xl:text-base'
                            headerClassName='text-sm 2xl:text-base bg-white' 
                        /> */}
                        <Column 
                            field="stage" 
                            header="Stage" 
                            className='text-sm 2xl:text-base'
                            headerClassName='text-sm 2xl:text-base bg-white' 
                        />
                        {/* <Column 
                            header="Score"
                            body={renderScore} 
                            className='text-sm 2xl:text-base'
                            headerClassName='text-sm 2xl:text-base bg-white' 
                        /> */}
                        <Column 
                            field="status" 
                            header="Status"
                            body={renderStatus}  
                            className='text-sm 2xl:text-base'
                            headerClassName='text-sm 2xl:text-base bg-white' 
                        />
                    </DataTable>
                </div>
                <div className={`${isUploading || isUploaded ?  'visible': 'hidden'} absolute top-0 h-screen w-screen left-0 z-50 bg-transparent flex justify-center items-center`}>
                    {isUploading && <LoadingComponent/>}
                    {isUploaded && <SuccessPopup message="All resumes have been uploaded successfully."/>}
                </div>
            </div>
        </section>
    )
};


export default ResumeSelectPopup;