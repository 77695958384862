import ReactPlayer from 'react-player';
import { useRef, useState } from 'react';
import { CgTranscript } from "react-icons/cg";
import { Rating } from '@smastrom/react-rating';
import { capitalizeFirstLetterOfEachWord } from '../../utils/common';
import { useReportContext } from '../../contextProviders/ReportContext';
import TranscriptComponent from '../../components/Report/TranscriptComponent';

export default function ReportOpsScreening(){
    const {report} = useReportContext();
    const [isPlaying, setIsPlaying] = useState(false);
    const [showTranscript, setShowTranscript] = useState(false);
    const opsScreening = report?.ops_screening?.[0] || {};

    const videoRef = useRef(null);

    const timestampToSeconds = (timestamp) => {
      const match = timestamp.match(/(\d{2}):(\d{2}):(\d{2}),(\d{3})/);
      if (!match) {
        throw new Error('Invalid timestamp format');
      }
  
      const [, hours, minutes, seconds, milliseconds] = match.map(Number);
      return hours * 3600 + minutes * 60 + seconds + milliseconds / 1000;
    };

    if(!report?.ops_screening || report?.ops_screening?.length === 0){
        return (
            <div className='flex flex-col gap-10'>
                <h1 className='font-medium text-base'>Ops screening report not available</h1>
            </div>
        )
    }

    const handlePlay = () => {
        setIsPlaying(true);
    };
    
    const handlePause = () => {
        setIsPlaying(false);
    };

    const handleSeek = (timestamp) => {
        try {
            const seconds = timestampToSeconds(timestamp);
            console.log(seconds);
            if (videoRef.current) {
                videoRef.current.seekTo(Math.floor(seconds), 'seconds');
                setIsPlaying(true);
            }
        } catch (error) {
          console.error('Error seeking video:', error);
        }
    };

    const getRating = (rating) => {
        if(rating < 20 || !rating){ 
            return {
                value: 1,
                title: 'REJECTED'
            }
        }
        else if(rating < 39){ 
            return {
                value: 2,
                title: 'CONSIDER ALTERNATE CANDIDATE'
            }
        }
        else if(rating < 59){ 
            return {
                value: 3,
                title: 'SUGGEST ON HOLD'
            }
        }
        else if(rating < 74){ 
            return {
                value: 4,
                title: 'CONSIDER PROCEEDING'
            }
        }
        else{ 
            return {
                value: 5,
                title: 'READY TO PROCEED'
            }
        }
    }

    return (
        <div className='flex flex-col gap-10'>
            <div className='flex flex-col gap-5'>
                <div className='flex flex-row justify-between flex-1 h-full gap-3'>
                    <ReactPlayer
                        ref={videoRef}
                        controls
                        pip={false}
                        playing={isPlaying}
                        onPlay={handlePlay}
                        onPause={handlePause}
                        className="react-player flex-1"
                        url={opsScreening?.video_path}
                    />
                    <div className='flex flex-col gap-4 items-start flex-1'>
                        <div className='flex flex-col gap-4 py-10 items-center w-full'>
                            <Rating 
                                readOnly
                                color='#B99000' 
                                cancel={false} 
                                style={{width: 120}}
                                value={getRating(report?.total_score).value} 
                            />
                            {/* <div className={`${report?.total_score >= 50 && report?.total_score < 80 ? 'visible': 'hidden'} flex flex-col items-center gap-4`}> */}
                                <h2 className='text-sm text-primary font-semibold'>{getRating(report?.total_score).title}</h2>
                            {/* </div> */}
                        </div>
                        <div className='flex items-center gap-2 text-primary'>
                            <h3 className='font-semibold'>Duration:</h3>
                            <span className=''>30 Minutes</span>
                        </div>
                        {/* <div className='flex items-center gap-2 text-primary'>
                            <h3 className='font-semibold'>Tab switches count:</h3>
                            <span className=''>{opsScreening?.anomalies_data?.tab_switches_count}</span>
                        </div>
                        <div className='flex items-center gap-2 text-primary'>
                            <h3 className='font-semibold'>Full Screen Exits Count:</h3>
                            <span className=''>{opsScreening?.anomalies_data?.full_screen_exits_count}</span>
                        </div> */}
                        {/* <div className='flex gap-2 text-primary'>
                            <h3 className='font-semibold'>Anomalies detected:</h3>
                            <ReactMarkdown>{opsScreening?.anomalies}</ReactMarkdown>
                        </div> */}
                        <div className='flex items-center gap-2 text-primary'>
                            <h3 className='font-semibold'>Facial Expression:</h3>
                            <span className=''>{opsScreening?.anomalies_data?.facial_expression}</span>
                        </div>
                        <div className='flex items-center gap-3'>
                            <p className='font-bold text-base'>Transcript of the interview</p>
                            <CgTranscript 
                                size={32}
                                className="cursor-pointer"
                                onClick={() => setShowTranscript(!showTranscript)}
                            />
                        </div>
                    </div>
                </div>
                {showTranscript && <TranscriptComponent id={opsScreening?.id} hide={() => setShowTranscript(false)}/>}
                {opsScreening?.summary && (
                    <div>
                        <h4 className='text-xl font-bold'>Summary</h4>
                        <p className='text-primary my-5'>{opsScreening?.summary}</p>
                    </div>
                )}
            </div>
            <div className='flex flex-col'>
                <h2 className='text-xl font-bold'>Primary Skills</h2>
                <ul className='flex gap-10 flex-wrap mt-5'>
                    {Object.keys(opsScreening?.primary_skills).map((item, index) => {
                        const object = opsScreening?.primary_skills[item];
                        const score = object.score;
                        return (
                            <li 
                                className='w-[30%]'
                                key={index?.toString()}>
                                <div className='flex justify-between items-center'>
                                    <h5 className='text-sm font-bold'>
                                        {capitalizeFirstLetterOfEachWord(item)}
                                    </h5>
                                    <Rating 
                                        readOnly
                                        color='#B99000' 
                                        cancel={false} 
                                        style={{width: 120}}
                                        value={score/20} 
                                    />
                                </div>
                                <div className='flex flex-col gap-2 mt-2'>
                                    <h5 className='font-semibold text-sm'>Reason:</h5>
                                    <p className='pb-3'>{object.reason}</p>
                                    <div className={`flex flex-col gap-3 ${opsScreening["video_snippets"]?.[item]?.length > 0 ? 'visible': 'hidden'}`}>
                                        <h3 className="font-semibold text-sm">Video Coverage</h3>
                                        <ul className={'flex flex-wrap gap-5'}>
                                            {opsScreening["video_snippets"]?.[item]?.map((item) => (
                                                <li key={item} className='font-semibold text-xs flex gap-5 text-blue'>
                                                    <span className="cursor-pointer" onClick={handleSeek.bind(this, item[0])}>{item[0]}</span>
                                                    <span  className="cursor-pointer" onClick={handleSeek.bind(this, item[1])}>{item[1]}</span>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </li>
                        )
                    })}
                </ul>
            </div>
            <div>
                <h2 className='text-xl font-bold'>Secondary Skills</h2>
                <ul className='flex gap-10 flex-wrap mt-5'>
                    {Object.keys(opsScreening?.secondary_skills).map((item, index) => {
                        const object = opsScreening.secondary_skills[item];
                        const score = object.score;
                        return (
                            <li
                                className='w-[30%]'
                                key={index?.toString()}>
                                <div className='flex justify-between items-center'>
                                    <h5 className='font-bold'>
                                        {capitalizeFirstLetterOfEachWord(item)}
                                    </h5>
                                    <Rating 
                                        readOnly
                                        color='#B99000' 
                                        cancel={false} 
                                        style={{width: 120}}
                                        value={score/20} 
                                    />
                                </div>
                                <div className='flex flex-col gap-2 mt-2'>
                                    <h5 className='font-semibold'>Reason:</h5>
                                    <p className='pb-3'>{object.reason}</p>
                                    <div className={`flex flex-col gap-3 ${opsScreening["video_snippets"]?.[item]?.length > 0 ? 'visible': 'hidden'}`}>
                                        <h3 className="font-semibold text-sm">Video Coverage</h3>
                                        <ul className={'flex flex-wrap gap-5'}>
                                            {opsScreening["video_snippets"]?.[item]?.map((item) => (
                                                <li key={item} className='font-semibold text-xs flex gap-5 text-blue'>
                                                    <span className="cursor-pointer" onClick={handleSeek.bind(this, item[0])}>{item[0]}</span>
                                                    <span  className="cursor-pointer" onClick={handleSeek.bind(this, item[1])}>{item[1]}</span>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </li>
                        )
                    })}
                </ul>
            </div>
        </div>
    )
}