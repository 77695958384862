import { TabView, TabPanel } from 'primereact/tabview';
import ReportInitialScreening from "./ReportInitalScreening";
import ReportResumeScreening from "./ReportResumeScreening";
import ReportTechnicalScreening from "./ReportTechnicalScreening";
import { useReportContext } from '../../contextProviders/ReportContext';
import { useState } from 'react';
import { useAuth } from '../../contextProviders/AuthContext';
import ReportOpsScreening from './ReportOpsScreening';

export default function DetailsReport(){
    const {user} = useAuth();
    const {report} = useReportContext();
    const [currentTabIndex, setCurrentTabIndex] = useState(0)

    return (
        <div className="px-5 bg-white rounded-md">
            <TabView
                tabIndex={1} 
                className='text-sm'
                activeIndex={currentTabIndex}
                pt={{inkbar: {className: 'bg-white'}}}
                onBeforeTabChange={(event) => {
                    setCurrentTabIndex(event.index)
                }}>
                <TabPanel
                    header="Resume Screening"
                    contentClassName='p-5'
                    pt={{headerTitle: {className: `${currentTabIndex === 0 ? 'bg-blue text-white': 'bg-[#F6F7F9] text-brownGray'} py-2 px-4 rounded-md text-sm font-normal`}}}>
                    <ReportResumeScreening />
                </TabPanel>
                {report?.initial_screening && (
                    <TabPanel 
                        header="Initial Screening"
                        contentClassName='p-5'
                        pt={{headerTitle: {className: `${currentTabIndex === 1 ? 'bg-blue text-white': 'bg-[#F6F7F9] text-brownGray'} py-2 px-4 rounded-md text-sm font-normal`}}}>
                        <ReportInitialScreening />
                    </TabPanel>
                )}
                {!user?.email?.includes('alorica') && report?.technical_screening && (
                    <TabPanel 
                        header='Technical screening'
                        contentClassName='p-5'
                        pt={{headerTitle: {className: `${currentTabIndex === 2 ? 'bg-blue text-white': 'bg-[#F6F7F9] text-brownGray'} py-2 px-4 rounded-md text-sm font-normal`}}}>
                        <ReportTechnicalScreening />
                    </TabPanel>
                )}
                {user?.email?.includes('alorica') && report?.ops_screening && (
                    <TabPanel 
                        header='OPS interview'
                        contentClassName='p-5'
                        pt={{headerTitle: {className: `${currentTabIndex === 2 ? 'bg-blue text-white': 'bg-[#F6F7F9] text-brownGray'} py-2 px-4 rounded-md text-sm font-normal`}}}>
                        <ReportOpsScreening />
                    </TabPanel>
                )}
            </TabView>
        </div>
    )
}