import axiosInstance from ".";

function login(data){
    return axiosInstance.post('/auth/login', data)
}
function getConfig(){
    return axiosInstance.get('/auth/config')
}
function updateConfig(data){
    return axiosInstance.put('/auth/config', data)
}
function refreshToken(data){
    return axiosInstance.post('/auth/refresh-tokens', data);
}
const AuthService = {
    login,
    getConfig,
    updateConfig,
    refreshToken
}
export default AuthService;