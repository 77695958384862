
import { Menu } from 'primereact/menu';
import { useRef, useState } from 'react';
import { MdOutlineKeyboardArrowDown, MdOutlineKeyboardArrowUp } from "react-icons/md";

export default function YearDropdown(props){
    const menu = useRef(null);
    const label = props?.label || 'Year';
    const [menuVisibility, setMenuVisibility] = useState(false);

    const menus = [
        {
          label: "2024",
          command: () => {
            props?.setLabel('2024');
            setMenuVisibility(false);
          }
        },
        {
          label: "2025",
          command: () => {
            props?.setLabel('2025');
            setMenuVisibility(false);
          }
        },
        {
          label: "2026",
          command: () => {
            props?.setLabel('2026');
            setMenuVisibility(false);
          }
        },
    ]

    const onClick = (event) => {                       
            if(menuVisibility) menu.current.hide(event)
            else menu.current.show(event)
            setMenuVisibility(!menuVisibility)
    }

    return (
        <div 
            onClick={onClick} 
            className="bg-white flex px-3 py-2 gap-5 justify-between items-center rounded cursor-pointer relative w-32 border-[1px] border-[#EAECF0]">
            <h4 className="text-sm">{label}</h4>
            {!menuVisibility && <MdOutlineKeyboardArrowDown size={22} /> }
            {menuVisibility && <MdOutlineKeyboardArrowUp size={22} /> }
            <Menu 
                popup 
                ref={menu}
                className="w-32"
                model={menus}
            />
        </div>
    )
}