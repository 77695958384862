function connectToInitialInterviewBot(token){
    return new WebSocket(`wss://${process.env.REACT_APP_API_URL?.replace('https://', '')}/ws/screening/initial?token=${token}`);
}

function connectToTechnicalInterviewBot(token){
    return new WebSocket(`wss://${process.env.REACT_APP_API_URL?.replace('https://', '')}/ws/screening/technical?token=${token}`);
}

function connectToBPOInitialInterviewBot(token){
    return new WebSocket(`wss://${process.env.REACT_APP_API_URL?.replace('https://', '')}/ws/parallel/screening/bpo/initial?token=${token}`);
}

function connectToBPOTechnicalInterviewBot(token){
    return new WebSocket(`wss://${process.env.REACT_APP_API_URL?.replace('https://', '')}/ws/screening/bpo/ops?token=${token}`);
}


// function connectToInitialInterviewBot(token){
//     return new WebSocket(`ws://localhost:8000/ws/screening/initial?token=${token}`);
// }

// function connectToTechnicalInterviewBot(token){
//     return new WebSocket(`ws://localhost:8000/ws/screening/technical?token=${token}`);
// }

const InterviewService = {
    connectToInitialInterviewBot,
    connectToTechnicalInterviewBot,
    connectToBPOInitialInterviewBot,
    connectToBPOTechnicalInterviewBot
}

export default InterviewService;