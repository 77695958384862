import React, { createContext, useState, useContext } from 'react';
import { useSessionContext } from './SessionContext';
import CommonService from '../services/common.service';
import { useLocation } from 'react-router-dom';

// Create the context
const AnomaliesContext = createContext();

// Create a provider component
export const AnomaliesProvider = ({ children }) => {
  const {search} = useLocation();
  const token = search?.split('token=')[1];
  const {sessionInfo} = useSessionContext();

  const [anomaliesData, setAnomaliesData] = useState({
    tab_switches_count: 0,
    full_screen_exits_count: 0,
    permissions_changed_count: 0,
    multi_monitor_detected_count: 0,
    face_missing: 0
  });

  const updateAnomalies = (newData) => {
    setAnomaliesData(prevData => ({
      ...prevData,
      ...newData
    }));
  };

  const uploadAnomalies = async () => {
    try {
      const response = await CommonService.uploadAnomalyInfo(token, {
        session_id: sessionInfo['session_id'],
        anomalies_data: anomaliesData
      });
      console.log('Upload successful:', response.data);
      return response.data;
    } catch (error) {
      console.error('Upload failed:', error);
      throw error;
    }
  };

  return (
    <AnomaliesContext.Provider value={{
      anomaliesData,
      updateAnomalies,
      // sessionId,
      // setSessionId,
      uploadAnomalies
    }}>
      {children}
    </AnomaliesContext.Provider>
  );
};

// Custom hook to use the context
export const useAnomalies = () => {
  const context = useContext(AnomaliesContext);
  if (!context) {
    throw new Error('useAnomalies must be used within an AnomaliesProvider');
  }
  return context;
};