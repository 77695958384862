import { useEffect, useState } from 'react';
import logo from './../../assets/icons/logo-3.svg';
import { MdOutlineAccessTime } from 'react-icons/md'
import { convertSecondsToMinutes } from '../../utils/date';

export default function InterviewRoomHeader({isRecording}){
    const [counter, setCounter] = useState(0);

    useEffect(() => {
        if(isRecording){
            const increment = () => {
                setCounter((prevCount) => prevCount + 1);
            };
            const intervalId = setInterval(increment, 1000);
            return () => {
                clearInterval(intervalId);
            };
        }
    }, [isRecording]);

    return (
        <div>
            <div className="flex m-auto md:w-[90%] p-5 justify-between items-center">
                <img 
                    src={logo}
                    alt='logo'
                    className='h-10 2xl:h-12'
                />
                <div className="flex gap-3 text-white items-center text-lg">
                    <MdOutlineAccessTime 
                        color="white"
                        size={20}
                    />
                    <span className='text-sm 2xl:text-base'>{convertSecondsToMinutes(counter)}</span>
                </div>
            </div>
        </div>
    )
}