import axios from "axios";

function getEvaluation(sessionId){
    const accessToken = JSON.parse(localStorage.getItem('accessToken'))
    return axios.get(`${process.env.REACT_APP_API_URL}/screening/evaluation/${sessionId}`, {
        headers: {
            'Authorization': `Bearer ${accessToken.token}`
        }
    })
}

const ScreeningService = {
    getEvaluation
}

export default ScreeningService;