import LoginPage from "../pages/Auth/LoginPage";
import { Navigate, Route, Routes } from "react-router-dom";

export default function AuthRoutes(){

    return (
        <Routes>
            <Route path="login" element={<LoginPage />}/>
            <Route path="*" element={<Navigate to="/notfound"/>}/>
        </Routes>
    )
}