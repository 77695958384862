import { Route, Routes } from "react-router-dom";
import InterviewHome from "../pages/Interview/InterviewHome";
import InterviewRoom from "../pages/Interview/InterviewRoom";
import InterviewLanding from "../pages/Interview/InterviewLanding";
import { SessionProvider } from "../contextProviders/SessionContext";
import { InterviewAuthProvider } from "../contextProviders/InterviewAuthContext";
import ProtectedRoute from "../components/Auth/ProtectedRoute";
import { InterviewMediaProvider } from "../contextProviders/InterviewMediaContext";
import { BotAudioProvider } from "../contextProviders/BotAudioContext";
import { MediaTestProvider } from "../contextProviders/MediaTestContext";
import { AnomaliesProvider } from "../contextProviders/AnomaliesContext";
import { MonitorProvider } from "../contextProviders/MonitorContext";

export default function CandidateRoutes(){
    return (
        <InterviewAuthProvider>
            <InterviewMediaProvider>
                <SessionProvider>
                <AnomaliesProvider>
                    <BotAudioProvider>
                        <MediaTestProvider>
                            <MonitorProvider>
                                    <Routes>
                                        <Route path="" element={<InterviewHome />}>
                                            <Route index path=":id" element={<ProtectedRoute element={<InterviewLanding />} />}/>
                                            <Route path="room/:id" element={<ProtectedRoute element={<InterviewRoom />} />}/>
                                            <Route path="*" element={<InterviewHome />}/>
                                        </Route>
                                    </Routes>
                            </MonitorProvider>
                        </MediaTestProvider>
                    </BotAudioProvider>
                    </AnomaliesProvider>
                </SessionProvider>
            </InterviewMediaProvider>
        </InterviewAuthProvider>
    )
}