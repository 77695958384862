import { InputText } from "primereact/inputtext";
import { IoSearchSharp } from "react-icons/io5";

export default function SearchbarComponent(props){
    return (
        <div className="bg-white h-10 px-2 flex rounded justify-center items-center w-1/4">
            <InputText
                color="#666666"
                placeholder="Search"
                className="text-sm h-10 flex-1" 
                value={props.label}
                onChange={(e) => props.onChange(e.target.value)} 
            />
            <IoSearchSharp 
                size={20} 
                color="#666666"
            />
        </div>
    )
}