import PropTypes from 'prop-types';
import { useState, useRef, useEffect } from 'react';
import { MdOutlineMicNone, MdMic } from "react-icons/md";
import { IoPlayCircleOutline, IoStopCircle } from "react-icons/io5";

function JDIdealCandidate(props){
    const {setActiveTabIndex, onFinalizedJD} = props;


    const audioRef = useRef(null);  
    const audioStream = useRef(null);
    const mediaRecorder = useRef(null);
    const [audioURL, setAudioURL] = useState(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [isRecording, setIsRecording] = useState(false);

    useEffect(() => {
        return () => {
            if(audioStream?.current && audioStream?.current.active)
                audioStream?.current.getTracks().forEach(track => track.stop());
        }
    }, []);
    
    const startRecording = async () => {
        try {
            setAudioURL(null);
            stopAudio();
            if(isRecording) return;
            audioStream.current = await navigator.mediaDevices.getUserMedia({ audio: true });
            mediaRecorder.current = new MediaRecorder(audioStream.current);
            mediaRecorder.current.start();
        
            const audioChunks = [];
            mediaRecorder.current.addEventListener("dataavailable", event => {
                audioChunks.push(event.data);
            });
        
            mediaRecorder.current.addEventListener("stop", () => {
                const audioBlob = new Blob(audioChunks);
                const audioUrl = URL.createObjectURL(audioBlob);
                setAudioURL(audioUrl);
            });
    
            setIsRecording(true);
        } catch (err) {
            console.error("Error accessing microphone:", err);
        }
    };
    
    const stopRecording = () => {
        mediaRecorder.current.stop();
        setIsRecording(false);
    };

    const playAudio = () => {
        if (audioRef.current) {
            audioRef.current.play();
            setIsPlaying(true)
        }
    };
    
    const stopAudio = () => {
        if (audioRef.current) {
            audioRef.current.pause();
            audioRef.current.currentTime = 0;
            setIsPlaying(false);
        }
    }

    const onBack = () => {
        setActiveTabIndex(2);
    }

    return (
        <div className="flex flex-col">
            <div className='bg-white p-5 rounded-md'>
                <div className="flex flex-col gap-5">
                    <h3 className="text-darkGray font-medium text-base">Describe ideal candidate (OPTIONAL)</h3>
                    <div className='flex items-center gap-5'>
                        <div onClick={startRecording} className={`border-[1px] ${isRecording ? 'border-primary': 'border-l_border'} h-12 w-12 rounded-full flex justify-center items-center text-primary cursor-pointer`}>
                            <div className={`border-[1px] ${isRecording ? 'border-primary': 'border-l_border'} h-10 w-10 rounded-full ${isRecording? 'bg-primary': 'bg-[#ECF5FA]'} flex justify-center items-center`}>
                                <MdMic size={21} className={`${isRecording ? 'visible text-white' : 'hidden'}`} />
                                <MdOutlineMicNone size={21} className={`${!isRecording ? 'visible' : 'hidden'}`} />
                            </div>
                        </div>
                        <button
                            onClick={stopRecording} 
                            className={`font-bold ${isRecording ? 'visible': 'hidden'}`}>
                            Stop Voice Recording
                        </button>
                        <div className={`${audioURL ? 'visible': 'hidden'} flex items-center gap-5 border-[1px] border-primary rounded-md h-10 px-3`}>
                            <span>{audioURL}</span>
                            <IoPlayCircleOutline 
                                size={20}
                                onClick={playAudio}
                                className={`cursor-pointer ${!isPlaying ? 'visible': 'hidden'}`}
                            />
                            <IoStopCircle 
                                size={20}
                                onClick={playAudio}
                                className={`cursor-pointer ${isPlaying ? 'visible': 'hidden'}`}
                            />
                        </div>
                        <audio 
                            ref={audioRef} 
                            src={audioURL}
                            onEnded={() => setIsPlaying(false)} 
                        />
                    </div>
                </div>
            </div>
            <div className="flex justify-between">
                <button 
                    onClick={onBack}
                    className='bg-blue h-10 text-sm px-10 rounded-md text-white font-semibold w-32 mt-10'>
                    Back
                </button>
                <button
                    onClick={onFinalizedJD} 
                    className='bg-blue h-10 text-sm px-10 rounded-md text-white font-semibold w-40 mt-10'>
                    Finalize JD
                </button>
            </div>
        </div>
    )
}

JDIdealCandidate.propTypes = {
    onFinalizedJD: PropTypes.func,
    setActiveTabIndex: PropTypes.func
}

export default JDIdealCandidate;