import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import JDDetailsResumes from "./JDDetailsResumes";
import JDService from "../../../services/jd.service";
import LoadingComponent from "../../../components/Common/LoadingComponent";
import JDDetailsHeaderSection from "../../../components/JD/JDDetailsHeaderSection";

export default function JDDetailsPage(){
    const {pathname} = useLocation();
    const [loading, setLoading] = useState(false);
    const [jobDescription, setJobDescription] = useState(null);
    const [menuVisibility, setMenuVisibility] = useState(null);
    const jdId = pathname.replace('/dashboard/job-description/', '');

    const handleMenuVisibility = (value) => {
        if(value !== menuVisibility)
            setMenuVisibility(value)
    }

    useEffect(() => {
        async function init(){
            try {
                setLoading(true);
                const response = await JDService.getJd(jdId);
                if(response.status === 200){
                    setJobDescription(response.data.data)
                }
                setLoading(false);
            } catch (error) {
                setLoading(false);
            }
        }
        init();
    }, []);

    if(loading){
        return (
            <div className="h-full w-full flex justify-center items-center">
                <LoadingComponent />
            </div>
        )
    }
    
    return (
        <div onClick={handleMenuVisibility.bind(this, false)}
            className="rounded flex flex-col overflow-y-scroll h-full p-5" >
            <JDDetailsHeaderSection jd={jobDescription}/>
            {/* <div className='flex gap-4 my-3'>
                <SearchbarComponent 
                    label={search}
                    onChange={setSearch}              
                />
                <ExperienceDropdownComponent 
                    label={selectedExperience}
                    setLabel={setSelectedExperience}
                />
                <LocationDropdownComponent 
                    label={selectedLocation}
                    setLabel={setSelectedLocation}
                />
            </div> */}
            <JDDetailsResumes 
                jd={jobDescription}
                menuVisibility={menuVisibility}
                handleMenuVisibility={handleMenuVisibility}
            />
        </div>
    )
}