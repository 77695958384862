import { useEffect, useState } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { useNavigate } from 'react-router-dom';
import { MdKeyboardArrowLeft } from "react-icons/md";
import { IoIosNotificationsOutline } from "react-icons/io";
import { useToast } from '../../contextProviders/ToastContext';
import LoadingComponent from '../../components/Common/LoadingComponent';
import NotificationComponent from "./../../components/HR/Dashboard/NotificationComponent";
import AuthService from '../../services/auth.service';
import { FRAME_RATES, RESOLUTIONS } from '../../utils/common';


export default function HRConfig(){

    const navigate = useNavigate();
    const goBack = () => navigate(-1);
    const [loading, setLoading] = useState(false);
    const [frameRate, setFrameRate] = useState(24);
    const { showErrorMessage, showSuccessMessage} = useToast();
    const [recordingResolution, setRecordingResolution] = useState(720);
    const [notificationsVisibility, setNotificationVisibility] = useState(false);
    const [previousConfig, setPreviousConfig] = useState({frameRate: 24, recordingResolution: 720})

    const toggleNotificationVisibility = () => {
        setNotificationVisibility(!notificationsVisibility);
    }

    console.log(previousConfig, frameRate, recordingResolution)
    const hasChanged = () => {
        if(previousConfig?.frameRate !== frameRate)
            return true;
        else if(previousConfig?.recordingResolution !== recordingResolution)
            return true;
        return false;
    }

    useEffect(() => {
        async function getConfig(){
            try {
                const response = await AuthService.getConfig();
                if(response.status === 200){
                    setPreviousConfig(response.data?.data);
                    setFrameRate(response.data?.data?.frameRate || 24);
                    setRecordingResolution(response.data?.data?.recordingResolution || 720);
                }
            } catch (error) {
                console.log(error);
            }
        }

        getConfig();
    }, []);

    const updateConfig = async () => {
        try {
            setLoading(true);
            const requestData = {};
            if(previousConfig?.frameRate !== frameRate)
                requestData['frameRate'] = frameRate;

            if(previousConfig?.recordingResolution !== recordingResolution)
                requestData['recordingResolution'] = recordingResolution;

            const response = await AuthService.updateConfig(requestData);
            setLoading(false)
            if(response.status === 200){
                setPreviousConfig({
                    frameRate,
                    recordingResolution
                });
                showSuccessMessage({summary: 'Updated', detail: "Updated config"})
            }
        } catch (error) {
            setLoading(false);
            showErrorMessage({summary: 'Failed', detail: "Failed to update config"})
        }
    }

    return (
        <div className="flex flex-col gap-5 p-5">
            <div className={`${loading? 'visible': 'hidden'} absolute h-screen w-screen bg-transparent top-0 left-0 z-10 flex justify-center items-center`}>
                <div className="bg-white p-10 rounded-md w-1/4 flex justify-center items-center">
                    <LoadingComponent/>
                </div>
            </div>
            <div className="flex flex-row items-center justify-between w-full">
                <div 
                    onClick={goBack}
                    className="flex items-center gap-1 text-[#161616] cursor-pointer">
                    <MdKeyboardArrowLeft size={26}/>
                    <h1 className='text-xl text-primary font-bold'>Config</h1>
                </div>
                <div className='flex gap-6 text-brownGray'>
                    <div className="flex justify-center">
                        <div className="bg-white h-10 w-10 rounded-full flex justify-center items-center cursor-pointer">
                            <IoIosNotificationsOutline size={26} color="#666666" onClick={toggleNotificationVisibility} />
                        </div>
                        <NotificationComponent visible={notificationsVisibility} toggle={toggleNotificationVisibility} />
                    </div>
                    {/* <div className="bg-white h-10 w-10 rounded-full flex justify-center items-center cursor-pointer">
                        <IoIosHelpCircleOutline size={26} color="#666666"/>
                    </div> */}
                </div>
            </div>
            <div className="bg-white rounded-md p-5 flex text-darkGray flex-col gap-5">
                <div className="flex-1 flex gap-2 justify-between items-center">
                    <label 
                        className="text-darkGray font-medium text-base" 
                        htmlFor="frameRate">* Frame Rate</label>
                    <Dropdown
                        checkmark={true} 
                        value={frameRate}
                        optionLabel="label"  
                        options={FRAME_RATES}  
                        defaultValue={frameRate}
                        highlightOnSelect={false}
                        placeholder="Select Frame Rate"
                        onChange={(e) => setFrameRate(e.value)}  
                        className="h-10 border-l_border border-[1px]" 
                        pt={{
                            itemLabel: {className: 'text-sm'},
                            input: {className: 'text-sm p-2 font-semibold text-sm'},
                        }} 
                    />
                </div>
                <div className="flex-1 flex gap-2 justify-between items-center">
                    <label 
                        className="text-darkGray font-medium text-base" 
                        htmlFor="recordingResolution">* Recording Resolution</label>
                    <Dropdown
                        checkmark={true} 
                        optionLabel="label"  
                        options={RESOLUTIONS}  
                        highlightOnSelect={false}
                        value={recordingResolution}
                        defaultValue={recordingResolution}
                        placeholder="Select Resolution"
                        onChange={(e) => setRecordingResolution(e.value)}  
                        className="h-10 border-l_border border-[1px]" 
                        pt={{
                            itemLabel: {className: 'text-sm'},
                            input: {className: 'text-sm p-2 font-semibold text-sm'},
                        }} 
                    />
                </div>
   
            </div>
            <button 
                onClick={updateConfig}
                disabled={!hasChanged()}
                className={`bg-blue text-white w-32 text-sm h-10 rounded-md font-semibold ${hasChanged() ? 'opacity-100': 'opacity-50'}`}>
                Update
            </button>
        </div>
    )
}