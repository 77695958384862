import React, { useState, useEffect } from 'react';
import uploadIcon from './../../assets/icons/upload-icon.svg';

const UploadAnimation = ({ isUploading, progress }) => {
  const [dots, setDots] = useState('');

  useEffect(() => {
    if (isUploading) {
      const interval = setInterval(() => {
        setDots(prev => (prev.length < 3 ? prev + '.' : ''));
      }, 500);
      return () => clearInterval(interval);
    }
  }, [isUploading]);

  return (
    <div className="upload-animation">
        <div className="upload-icon">
            <img 
                alt='upload'
                src={uploadIcon} 
                className='h-20'
            />
        </div>
    </div>
  );
};

export default UploadAnimation;