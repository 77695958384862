import React, { createContext, useState, useContext, useEffect } from 'react';
import testAudio from './../assets/audios/test.mp3';
import { useMediaContext } from './InterviewMediaContext';
import { useSessionContext } from './SessionContext';
import { RESOLUTIONS } from '../utils/common';
import LogService from '../services/log.service';

const MediaTestContext = createContext();

// Create a provider component
export const MediaTestProvider = ({ children }) => {
  const {sessionInfo} = useSessionContext();
  const [cameraStatus, setCameraStatus] = useState('untested');
  const [speakerStatus, setSpeakerStatus] = useState('untested');
  const [microphoneStatus, setMicrophoneStatus] = useState('untested');
  const [screenSharingStatus, setScreenSharingStatus] = useState('untested');
  const {setDisplayStream, setAudioStream, setVideoStream} = useMediaContext();
  const token = sessionInfo?.token || localStorage.getItem('sessionToken');

  useEffect(() => {
    const checkPermissions = async () => {
      try {
        const micPermission = await navigator.permissions.query({ name: 'microphone' });
        const cameraPermission = await navigator.permissions.query({ name: 'camera' });

        setMicrophoneStatus(micPermission.state);
        setCameraStatus(cameraPermission.state)

        micPermission.onchange = () => {
          setMicrophoneStatus(micPermission.state);
        };

        cameraPermission.onchange = () => {
          setCameraStatus(cameraPermission.state);
        };
      } catch (error) {
        console.error('Error checking permissions:', error);
      }
    };

    checkPermissions();
  }, []);


  const requestMicrophonePermission = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      setAudioStream(stream);
      // stream.getTracks().forEach(track => track.stop());
      setMicrophoneStatus('working');
    } catch (error) {
      setMicrophoneStatus('denied');
      console.error('Microphone test failed:', error);
    }
  };

  const requestCameraPermission = async () => {
    try {
      const videoStream = await navigator.mediaDevices.getUserMedia({
        video: {
          frameRate: { 
            exact: sessionInfo?.config?.frameRate || 24 
          },
          width: { 
            exact: RESOLUTIONS.find((item) => item.value === sessionInfo?.config?.recordingResolution)?.width || 1280 
          },
          height: { 
            exact: RESOLUTIONS.find((item) => item.value === sessionInfo?.config?.recordingResolution)?.height || 720 
          },
        }, 
        audio: false,
        playinline: true 
    });
    LogService.uploadLog(token, sessionInfo?.session_id, `requestCameraPermission: ${JSON.stringify(
      {
          frameRate: { 
            exact: sessionInfo?.config?.frameRate || 24 
          },
          width: { 
            exact: RESOLUTIONS.find((item) => item.value === sessionInfo?.config?.recordingResolution)?.width || 1280 
          },
          height: { 
            exact: RESOLUTIONS.find((item) => item.value === sessionInfo?.config?.recordingResolution)?.height || 720 
          },
        }, 
    )}`);
      setVideoStream(videoStream);
      // stream.getTracks().forEach(track => track.stop());
      setCameraStatus('working');
    } catch (error) {
      setCameraStatus('denied');
      console.error('Camera test failed:', error);
    }
  };

  const requestScreeningSharingPermission = async () => {
    try {
      const displayStream = await navigator.mediaDevices.getDisplayMedia({
        video: {
            displaySurface: "monitor",
            cursor: "always",
            frameRate: { 
              ideal: sessionInfo?.config?.frameRate || 24 
            },
            width: { 
              ideal: RESOLUTIONS.find((item) => item.value === sessionInfo?.config?.recordingResolution)?.width || 1280 
            },
            height: { 
              ideal: RESOLUTIONS.find((item) => item.value === sessionInfo?.config?.recordingResolution)?.height || 720 
            },
        },
        audio: false
      });
      LogService.uploadLog(token, sessionInfo?.session_id, `requestScreeningSharingPermission: ${JSON.stringify({
        displaySurface: "monitor",
        cursor: "always",
        frameRate: { 
          ideal: sessionInfo?.config?.frameRate || 24 
        },
        width: { 
          ideal: RESOLUTIONS.find((item) => item.value === sessionInfo?.config?.recordingResolution)?.width || 1280 
        },
        height: { 
          ideal: RESOLUTIONS.find((item) => item.value === sessionInfo?.config?.recordingResolution)?.height || 720 
        },
      })}`);
      setDisplayStream(displayStream);
      // stream.getTracks().forEach(track => track.stop());
      setScreenSharingStatus('working');
    } catch (error) {
      setScreenSharingStatus('denied');
      console.error('Screen sharing test failed:', error);
    }
  };

  const requestSpeakerPermission = () => {
    
    const audio = new Audio(testAudio);
    audio.onended = () => {
      setSpeakerStatus('working');
    };
    audio.onerror = () => {
      setSpeakerStatus('denied');
      console.error('Speaker test failed');
    };
    audio.play();
  };

  const value = {
    microphoneStatus,
    cameraStatus,
    screenSharingStatus,
    speakerStatus,
    requestMicrophonePermission,
    requestCameraPermission,
    requestScreeningSharingPermission,
    requestSpeakerPermission,
  };

  return (
    <MediaTestContext.Provider value={value}>
      {children}
    </MediaTestContext.Provider>
  );
};

// Custom hook to use the context
export const useMediaTest = () => {
  const context = useContext(MediaTestContext);
  if (context === undefined) {
    throw new Error('useMediaTest must be used within a MediaTestProvider');
  }
  return context;
};