import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { MdKeyboardArrowLeft } from "react-icons/md";
import {InputTextarea} from 'primereact/inputtextarea';
import { IoIosNotificationsOutline } from "react-icons/io";
import NotificationComponent from "./../../components/HR/Dashboard/NotificationComponent";
import LoadingComponent from '../../components/Common/LoadingComponent';
import SupportService from '../../services/support.service';
import { useToast } from '../../contextProviders/ToastContext';

export default function HRSupport(){
    const navigate = useNavigate();
    const goBack = () => navigate(-1);
    const [message, setMessage] = useState(null);
    const [loading, setLoading] = useState(false);
    const { showErrorMessage, showSuccessMessage} = useToast();

    const [notificationsVisibility, setNotificationVisibility] = useState(false);

    const toggleNotificationVisibility = () => {
        setNotificationVisibility(!notificationsVisibility);
    }

    const submitSupport = async () => {
        try {
            if(!message || message?.trim()?.length === 0) {
                showErrorMessage({summary: 'Required', detail: "Message field required"})
                return;
            };
            setLoading(true);
            const response = await SupportService.createSupport({message});
            setLoading(false)
            if(response.status === 201){
                setMessage(null);
                showSuccessMessage({summary: 'Submitted', detail: "Support submitted"})
            }
        } catch (error) {
            setLoading(false);
            showErrorMessage({summary: 'Failed', detail: "Failed to submit support"})
        }
    }

    return (
        <div className="flex flex-col gap-5 p-5">
            <div className={`${loading? 'visible': 'hidden'} absolute h-screen w-screen bg-transparent top-0 left-0 z-10 flex justify-center items-center`}>
                <div className="bg-white p-10 rounded-md w-1/4 flex justify-center items-center">
                    <LoadingComponent/>
                </div>
            </div>
            <div className="flex flex-row items-center justify-between w-full">
                <div 
                    onClick={goBack}
                    className="flex items-center gap-1 text-[#161616] cursor-pointer">
                    <MdKeyboardArrowLeft size={26}/>
                    <h1 className='text-xl text-primary font-bold'>Support</h1>
                </div>
                <div className='flex gap-6 text-brownGray'>
                    <div className="flex justify-center">
                        <div className="bg-white h-10 w-10 rounded-full flex justify-center items-center cursor-pointer">
                            <IoIosNotificationsOutline size={26} color="#666666" onClick={toggleNotificationVisibility} />
                        </div>
                        <NotificationComponent visible={notificationsVisibility} toggle={toggleNotificationVisibility} />
                    </div>
                    {/* <div className="bg-white h-10 w-10 rounded-full flex justify-center items-center cursor-pointer">
                        <IoIosHelpCircleOutline size={26} color="#666666"/>
                    </div> */}
                </div>
            </div>
            <div className="bg-white rounded-md p-5 flex text-darkGray flex-col">
                <h2 className='text-lg text-primary font-semibold'>Support Request:</h2>
                <p className='py-3 font-medium text-sm'>We value your feedback. Please take a moment to share your interview experience and any feedback you may have</p>
                <InputTextarea 
                    rows={10}
                    value={message}
                    defaultValue={message}
                    className='bg-[#F6F7F9] p-2' 
                    onChange={(e) => setMessage(e.target.value)}
                />
            </div>
            <button 
                onClick={submitSupport}
                className='bg-blue text-white w-32 text-sm h-10 rounded-md font-semibold'
                >Submit</button>
        </div>
    )
}