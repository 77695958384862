import axios from "axios";
import axiosInstance from ".";

async function generatePresignUrlForResume(filename){
    return axiosInstance.get(`/generatePresignUrl?filename=${filename}&containerName=resumes`)
}

async function generatePresignUrlForJD(filename){
    return axiosInstance.get(`/generatePresignUrl?filename=${filename}&containerName=jds`)
}

async function generatePresignUrlForSessionFileUpload(sessionId, filename, token){
    return axios.get(`${process.env.REACT_APP_API_URL}/api/v1/sessions/${sessionId}/generatePresignUrl?filename=${filename}`, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    })
}

function getHtmlFromText(data){
    return axiosInstance.post('/textToHtml', data)
}

function extractJDInfo(data){
    return axiosInstance.post('/extractJDInfo', data)
}

function getAnalytics(year){
    return axiosInstance.get(`/hr/dashboard/analytics?year=${year}`)
}

function generatePdfFromHtml(data){
    return axiosInstance.post('/htmlToPdf', data, {
        headers: {
            'Content-Type': 'text/html'
        }
    })
}

function getSessionInfo(token){
    return axiosInstance.get(`/getSessionInfo?token=${token}`)
}

function uploadAnomalyInfo(token, data){
    return axios.post(`${process.env.REACT_APP_API_URL}/screening/session/anomaly?token=${token}`, data)
}

function getSpeechToken(token){
    return axiosInstance.get(`/get-speech-token?token=${token}`)
}


function submitSessionFeedback(data){
    return axiosInstance.post('/submitSessionFeedback', data)
}

function submitTrialRequest(data){
    return axiosInstance.post('/trials', data)
}

function submitEnquiryRequest(data){
    return axiosInstance.post('/enquiries', data)
}


const CommonService = {
    getAnalytics,
    extractJDInfo,
    getSessionInfo,
    getSpeechToken,
    getHtmlFromText,
    uploadAnomalyInfo,
    submitEnquiryRequest,
    submitTrialRequest,
    generatePdfFromHtml,
    submitSessionFeedback,
    generatePresignUrlForJD,
    generatePresignUrlForResume,
    generatePresignUrlForSessionFileUpload
}

export default CommonService;