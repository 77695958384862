import { MdClear } from "react-icons/md";
import PropTypes from 'prop-types';

function AudioAnalysisReportPopup(props){
    const {close, audioAnalysis} = props;

    return (
        <div className="absolute top-0 left-0 bottom-6 h-screen w-screen flex justify-center items-center bg-transparent z-50 overflow-hidden">
            <div className='w-1/2 max-h-[95%] flex flex-col gap-5 relative'>
                <div
                    onClick={close}
                    className='rounded-full shadow-md top-6 absolute z-20 -right-2 h-8 w-8 bg-white flex justify-center items-center text-darkGray cursor-pointer'>
                    <MdClear size={20}/>
                </div>
                <div className='my-10 max-h-[95%] flex flex-col gap-6 bg-white p-5 rounded-xl overflow-y-scroll'>
                    <h1 className="text-xl font-bold text-primary">Audio Analysis</h1>
                    <div className={`flex gap-2 ${audioAnalysis?.speech_quality ? 'visible' : 'hidden'}`}>
                        <h2 className="font-semibold text-darkGray">Speech Quality : </h2>
                        <span className="text-blue font-medium">{audioAnalysis.speech_quality}</span>
                    </div>
                    <div className={`flex gap-2 ${audioAnalysis?.audio_volume ? 'visible' : 'hidden'}`}>
                        <h2 className="font-semibold text-darkGray">Audio Volume : </h2>
                        <span className="text-blue font-medium">{audioAnalysis.audio_volume}</span>
                    </div>
                    <div className={`flex gap-2 ${audioAnalysis?.audio_quality ? 'visible' : 'hidden'}`}>
                        <h2 className="font-semibold text-darkGray">Audio Quality : </h2>
                        <span className="text-blue font-medium">{audioAnalysis.audio_quality}</span>
                    </div>
                    <div className={`flex flex-col gap-2 ${audioAnalysis?.filler_words && Object.keys(audioAnalysis?.filler_words).length ? 'visible' : 'hidden'}`}>
                        <h2 className="font-semibold text-darkGray">Filler words : </h2>
                        <ul className="">
                            {Object.keys(audioAnalysis?.filler_words)?.map((item) => (
                                <li className="flex gap-2">
                                    <h3 className="font-medium w-20">{item}</h3>
                                    <span>{audioAnalysis?.filler_words[item]} times</span>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className={`flex flex-col gap-2 ${audioAnalysis?.most_repeated_words && Object.keys(audioAnalysis?.most_repeated_words).length ? 'visible' : 'hidden'}`}>
                        <h2 className="font-semibold text-darkGray">Repeated words</h2>
                        <ul className="">
                            {Object.keys(audioAnalysis?.most_repeated_words)?.map((item) => (
                                <li className="flex gap-2">
                                    <h3 className="font-medium w-20">{item}</h3>
                                    <span>{audioAnalysis?.most_repeated_words[item]} times</span>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

AudioAnalysisReportPopup.propTypes = {
    close: PropTypes.func,
    audioAnalysis: PropTypes.any
}

export default AudioAnalysisReportPopup;