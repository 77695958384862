import ReactGA from 'react-ga4';

const isDev = !window.location.host?.includes('localhost') && !window.location.host?.includes('staging');

// Initialize Google Analytics
export const initGA = ({trackingId}) => {
    if(!isDev) return;

    ReactGA.initialize(trackingId);
};

// Track page views
export const trackPageView = ({path}) => {
    if(!isDev) return;

    ReactGA.set({ page: path });
    ReactGA.send(path);
};

// Event tracking
export const trackEvent = ({category, action, label, value = 1}) => {
    if(!isDev) return;

    ReactGA.event({
        category,
        action,
        label,
        value,
        nonInteraction: false,
        transport: 'beacon'
    });
};
