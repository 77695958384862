
import React, { createContext, useState, useRef, useEffect } from 'react';

const BotAudioContext = createContext();

export const BotAudioProvider = ({ children }) => {
    const audioRef = useRef(new Audio());
    const [isPlaying, setIsPlaying] = useState(false);
    const [hasPlayed, setHasPlayed] = useState(false);
    const [botAudioSource, setBotAudioSource] = useState(null);

    useEffect(() => {
        console.log("BotAudioProvider", 'isPlaying', isPlaying)
        console.log("BotAudioProvider", 'hasPlayed', hasPlayed)
        console.log("BotAudioProvider", 'botAudioSource', botAudioSource)

        if (botAudioSource && audioRef.current) {
            setHasPlayed(false);
            audioRef.current.src = botAudioSource;
            audioRef.current.play();
        }
    }, [botAudioSource]);

    const play = () => {
        console.log('BotAudioProvider play');
        if(audioRef.current)
            audioRef.current.play();
    };

    const stop = () => {
        console.log('BotAudioProvider stop');
        if(audioRef?.current && hasPlayed){
            audioRef.current.stop();
            setIsPlaying(false);
        }
    };

    const setAudioSource = (newSrc) => {
        console.log('BotAudioProvider setAudioSource');
        setBotAudioSource(newSrc);
    };

    return (
        <BotAudioContext.Provider 
            value={{
                play, 
                stop, 
                isPlaying, 
                hasPlayed,
                setAudioSource
            }}>
                {children}
            <audio 
                ref={audioRef} 
                onPlaying={() => {
                    console.log('BotAudioProvider onPlaying');
                    setIsPlaying(true)
                }}
                onEnded={() => {
                    setIsPlaying(false);
                    console.log('BotAudioProvider onEnded');
                    setHasPlayed(true);
                }}
            />
        </BotAudioContext.Provider>
    );
};

export const useBotAudio = () => {
    return React.useContext(BotAudioContext);
};
