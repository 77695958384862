import { Dialog } from 'primereact/dialog';
import { useCallback, useEffect, useState } from 'react';
import {MdInfoOutline} from 'react-icons/md';
import { useMediaTest } from '../../contextProviders/MediaTestContext';
import { useMonitorStatus } from '../../contextProviders/MonitorContext';
import { useMediaContext } from '../../contextProviders/InterviewMediaContext';

export default function InterviewPermissionDialog(){
    const [screenType, setScreenType] = useState(null);
    const [hasAllPermissions, setHasAllPermissions] = useState(true);
    const [missingPermissions, setMissingPermissions] = useState({});
    const {videoStream, audioStream, displayStream} = useMediaContext();
    const {
        requestMicrophonePermission,
        requestCameraPermission,
        requestScreeningSharingPermission,
    } = useMediaTest();
    

    const checkAllPermissions = useCallback(() => {
        const requiredPermissions = {};
        let allPermissionsGranted = true;
    
        if (!audioStream?.active) {
            requiredPermissions['microphone'] = 'Microphone access is needed to record your interview responses.';
            allPermissionsGranted = false;
        }
        if (!videoStream?.active) {
            requiredPermissions['camera'] = 'Camera access is required for facial expression analysis.';
            allPermissionsGranted = false;
        }
        if (!displayStream?.active) {
            setScreenType(null);
            requiredPermissions['screen-sharing'] = 'Screen sharing is necessary for task observation.';
            allPermissionsGranted = false;
        }
        else {
            const screenType = displayStream.getVideoTracks()[0].getSettings().displaySurface;
            setScreenType(screenType);
            if(screenType !== "monitor"){
                requiredPermissions['screen-sharing'] = 'Please click on stop sharing and then share your Entire screen.';
                allPermissionsGranted = false;
            }
        }
    
        setMissingPermissions(requiredPermissions);
        setHasAllPermissions(allPermissionsGranted);
    }, [audioStream, videoStream, displayStream]);
    

    const requestRequiredPermissions = async () => {
        try {
            const permissions = [];
    
            if (!displayStream?.active) {
                permissions.push(requestScreeningSharingPermission());
            }
            if (!audioStream?.active) {
                permissions.push(requestMicrophonePermission());
            }
            if (!videoStream?.active) {
                permissions.push(requestCameraPermission());
            }
    
            await Promise.all(permissions);
        } catch (error) {
            console.error('Error requesting permissions:', error);
            // Handle the error appropriately, e.g., show a user-friendly message
        }
    };
    useEffect(() => {
        const interval = setInterval(checkAllPermissions, 2000);
        return () => clearInterval(interval);
    }, [checkAllPermissions]);    


    if(hasAllPermissions) return <></>

    return (
        <Dialog 
            header={
                <div className='flex gap-5 items-center text-primary justify-between'>
                    <h1>{`${screenType !== 'monitor' && screenType !== null ? 'Please share Entire screen': 'Permissions required' }`}</h1>
                    <MdInfoOutline size={36}/>
                </div>
            }
            visible={!hasAllPermissions}
            closable={false}
            draggable={false}
        >
            <div className='flex flex-col justify-center items-center gap-10'>
                <ul className='flex flex-col gap-4 items-start w-full'>
                    {Object.values(missingPermissions)?.map((item, index) => (
                        <li key={item} className='text-sm font-medium'>
                            <span>{index + 1}. {item}</span>
                        </li>
                    ))}
                </ul>
                <button 
                    className={`px-10 h-11 border bg-primary rounded-md text-white text-sm font-semibold ${screenType !== 'monitor' && screenType !== null ? 'hidden': 'visible'}`}
                    onClick={requestRequiredPermissions}>
                    Grant all required permissons
                </button>
            </div>
        </Dialog>
    )
}