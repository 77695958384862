import PropTypes from 'prop-types';
import { MdCheck } from "react-icons/md";

function CheckboxComponent({label, value, setValue}){
    const onClick = () => {
        setValue(label, !value)
    };

    return (
        <div 
            onClick={onClick} 
            className="h-5 w-5 border-primary border-2 rounded text-primary flex justify-center items-center cursor-pointer">
            <MdCheck 
                size={22} 
                className={`${value ? 'visible': 'hidden'}`}
            />
        </div>
    )
}
CheckboxComponent.propTypes = {
    label: PropTypes.string,
    value: PropTypes.bool,
    setValue: PropTypes.func
}

export default CheckboxComponent;