import * as faceapi from 'face-api.js';

const isFullFace = (detection, imageWidth, imageHeight) => {
    if (!detection || !detection.landmarks) return false;
  
    const landmarks = detection.landmarks;
    const nose = landmarks.getNose();
    const leftEye = landmarks.getLeftEye();
    const rightEye = landmarks.getRightEye();

    if (nose.length === 0 || leftEye.length === 0 || rightEye.length === 0) {
      return false;
    }
  
    const faceBox = detection.detection.box;
    const margin = 0.1;
  
    const isWithinFrame = 
      faceBox.x > imageWidth * margin &&
      faceBox.x + faceBox.width < imageWidth * (1 - margin) &&
      faceBox.y > imageHeight * margin &&
      faceBox.y + faceBox.height < imageHeight * (1 - margin);
  
    return !isWithinFrame ? false : true;
};

const loadModels = async () => {
    const MODEL_URL = process.env.PUBLIC_URL + '/models';
    
    return Promise.all([
      faceapi.nets.tinyFaceDetector.loadFromUri(MODEL_URL),
      faceapi.nets.faceLandmark68Net.loadFromUri(MODEL_URL),
      faceapi.nets.faceRecognitionNet.loadFromUri(MODEL_URL),
      faceapi.nets.faceExpressionNet.loadFromUri(MODEL_URL)
    ]);
}

const getFullFaceDetections = async (video, displaySize) => {

    const detections = await faceapi.detectAllFaces(video, new faceapi.TinyFaceDetectorOptions())
    .withFaceLandmarks()
    .withFaceExpressions();

    // const resizedDetections = faceapi.resizeResults(detections, displaySize);

    // const fullFaceDetections = resizedDetections.filter(detection => 
    //     isFullFace(detection, video.videoWidth, video.videoHeight)
    // );
    return detections.length > 0;

}


const isFaceDetected = async (video) => {

  const detections = await faceapi.detectAllFaces(video, new faceapi.TinyFaceDetectorOptions())
  .withFaceLandmarks()
  .withFaceExpressions();

  // const resizedDetections = faceapi.resizeResults(detections, displaySize);

  // const fullFaceDetections = resizedDetections.filter(detection => 
  //     isFullFace(detection, video.videoWidth, video.videoHeight)
  // );
  return detections.length > 0;

}

const FaceDetection = {
    getFullFaceDetections,
    loadModels,
    isFaceDetected,
}

export default FaceDetection