
import { Menu } from 'primereact/menu';
import { useRef, useState } from 'react';
import { MdOutlineKeyboardArrowDown, MdOutlineKeyboardArrowUp } from "react-icons/md";

export default function JobProfileDropdownComponent(props){
        const menu = useRef(null);
        const label = props?.label || 'Job Profiles';
    
        const menus = [
            {
              label: "Developer",
              command: () => {
                props?.setLabel('Developer');
                setMenuVisibility(false);
              }
            },
            {
              label: "AI Developer",
              command: () => {
                props?.setLabel('AI Developer');
                setMenuVisibility(false);
              }
            },
            {
              label: "UI/UX Designer",
              command: () => {
                props?.setLabel('UI/UX Designer');
                setMenuVisibility(false);
              }
            },
        ]
    
        const [menuVisibility, setMenuVisibility] = useState(false);
    
        const onClick = (event) => {                       
                if(menuVisibility) menu.current.hide(event)
                else menu.current.show(event)
                setMenuVisibility(!menuVisibility)
        }
    
        return (
            <div 
                onClick={onClick} 
                className="bg-white flex px-3 py-2 gap-5 justify-between items-center rounded cursor-pointer relative border-[1px] border-[#EAECF0]">
                <h4 className="text-sm">{label}</h4>
                {!menuVisibility && <MdOutlineKeyboardArrowDown size={21} /> }
                {menuVisibility && <MdOutlineKeyboardArrowUp size={21} /> }
                <Menu 
                    popup 
                    ref={menu}
                    className="w-32"
                    model={menus}
                />
            </div>
        )
    }