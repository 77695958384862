import React, { useEffect, useRef, useState } from 'react';
import { useSessionContext } from '../../contextProviders/SessionContext';
import { useMediaContext } from '../../contextProviders/InterviewMediaContext';

const BotComponent = (props) => {
    const source = useRef();
    const analyser = useRef();
    const audioContext = useRef();
    const audioRef = useRef(null);
    const videoRef = useRef(null);
    const {
        isConnected, 
        onCompleted,
        isAloricaFlow,
        isConnectionIssue, 
    } = props;

    const {audioStream} = useMediaContext();
    const [message, setMessage] = useState(null);
    const [isSpeaking, setIsSpeaking] = useState(false);

    const {
        transcripts,
        messageQueue, 
        setMessageQueue,
        setIsBotSpeaking,
        addMessageToTranscript,
    } = useSessionContext();

    const isMicOn = (state = true) => {
        const audioTracks = audioStream?.getAudioTracks();
        audioTracks?.forEach(track => {track.enabled = state});     
    }

    useEffect(() => {
        const audio = audioRef.current;

        if(!audioContext.current){
            audioContext.current = new (window.AudioContext || window.webkitAudioContext)();
            analyser.current = audioContext.current.createAnalyser();
            source.current = audioContext.current.createMediaElementSource(audio);
        }
    
        source.current.connect(analyser.current);
        analyser.current.connect(audioContext.current.destination);
        const dataArray = new Uint8Array(analyser.current.frequencyBinCount);
    
        const detectSilence = () => {
          analyser.current.getByteFrequencyData(dataArray);
    
          const average = dataArray.reduce((a, b) => a + b, 0) / dataArray.length;
          if (average < 25) {
            if(isSpeaking)
                stopLipSyncing();
          }
        };
    
        const checkSilenceInterval = setInterval(detectSilence, 100); // Check every 100ms
    
        return () => {
          clearInterval(checkSilenceInterval);
          analyser.current.disconnect();
          source.current.disconnect();
        };
    
    }, [])
    

    useEffect(() => {
        if (audioRef.current && messageQueue.length > 0 && !isSpeaking) {
            const message = messageQueue[0];
            const exists = transcripts.some(obj => obj?.questionId === message?.questionId);
            console.log('message', message, 'exists', exists);
            if(!exists || !message?.questionId){
                const binaryString = atob(message?.audio_stream);
                const bytes = new Uint8Array(binaryString.length);
                for (let i = 0; i < binaryString.length; i++) {
                    bytes[i] = binaryString.charCodeAt(i);
                }
                const blob = new Blob([bytes], { type: 'audio/mpeg' });
                const url = URL.createObjectURL(blob);
                addMessageToTranscript(message);
                setMessage(message);
                audioRef.current.src = url;
                const playPromise = audioRef.current.play();
    
                if (playPromise !== undefined) {
                    playPromise.catch(error => {setIsBotSpeaking(false)});
                }
            }
            else{
                setMessageQueue((prevQueue) => prevQueue.slice(1));
            }
        }
    }, [audioRef, isSpeaking, messageQueue])

    useEffect(() => {
        if(videoRef.current)
            videoRef.current.currentTime = 2;
    }, [videoRef.current])

    const playLipSyncing = () => {
        if (videoRef.current) {
            videoRef.current.play();
        }
    }

    const stopLipSyncing = () => {
        if (videoRef.current) {
            videoRef.current.pause();
            videoRef.current.pause();
            videoRef.current.currentTime = 2;
        }
    }

    const ringTheme = !isConnectionIssue && isConnected ? 'ring-4 ring-[#4DC284] ring-offset-4 ring-offset-white'
        : !isConnected && !isConnectionIssue ? 'ring-4 ring-do ring-offset-4 ring-offset-white' : 'ring-4 ring-dr ring-offset-4 ring-offset-white'

    return (
        <div className={`${isSpeaking ? 'animate-scaleUp' : 'animate-scaleDown'} py-2 border-4 h-32 w-32 2xl:w-38 2xl:h-38 justify-center items-center flex flex-col gap-2`}>
            <div className='flex justify-center items-center'>
                <video 
                    loop
                    muted={true}
                    ref={videoRef}
                    autoPlay={false}
                    className={`h-32 w-32 2xl:w-38 2xl:h-38 ${ringTheme}`}
                    src={isAloricaFlow ? 
                        'https://skillassessment.blob.core.windows.net/files/alorica.mp4': 'https://skillassessment.blob.core.windows.net/files/non-alorica.mp4'}
                />
            </div>
            <audio 
                ref={audioRef}
                id='bot-audio'  
                onPlaying={() => {
                    setIsBotSpeaking(true);
                    setIsSpeaking(true);
                    playLipSyncing();
                }}
                onEnded={() => {
                    if(message?.type !== 'ACKNOWLEDGEMENT' || message?.type === 'NEXT_QUESTION')
                        isMicOn(true);
                    stopLipSyncing();
                    setTimeout(() => {
                        setIsSpeaking(false)
                        setIsBotSpeaking(false);
                        setMessageQueue((prevQueue) => prevQueue.slice(1));

                        if(message?.is_completed)
                            onCompleted();
                    }, 1000)
                }}
            />
        </div>
    )
}

export default React.memo(BotComponent);