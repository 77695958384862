import HRRoutes from "./HRRoutes";
import AuthRoutes from "./AuthRoutes";
import ROLES from "../constants/roles";
import NotFound from "../pages/NotFound";
import { useEffect, useState } from "react";
import PricingPage from "../pages/PricingPage";
import AboutUsPage from "../pages/AboutUsPage";
import LandingPage from "../pages/LandingPage";
import InterviewRoutes from "./InterviewRoutes";
import Layout from "../components/Common/Layout";
import ProductsPage from "../pages/ProductsPage";
import SolutionPage from "../pages/SolutionPage";
import { useAuth } from "../contextProviders/AuthContext";
import LoadingComponent from "../components/Common/LoadingComponent";
import { Navigate, Route, Routes, useLocation } from "react-router-dom"
import BookDemoPage from "../pages/BookDemoPage";
import TrialRequestPage from "../pages/TrialRequestPage";
import Sitemap from "../pages/Sitemap";


export default function RootRoutes(){
    const {user} = useAuth();
    const location = useLocation();
    const [loading, setLoading] = useState(true);
    const [userRole, setUserRole] = useState(null);
    const [isAuthenticate, setIsAuthenticate] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [location.pathname])
        
    useEffect(() => {
        const timeout = setTimeout(() => {
            setLoading(false)
            if(!location.pathname.includes('interview')){
                if(user) {
                    setUserRole(user.role)
                    setIsAuthenticate(true);
                }
                else setIsAuthenticate(false)
            }
        }, 500);
        return () => clearTimeout(timeout);

    }, [user]);

    // function getRoutes(userRole) {
    //     switch (userRole) {
    //       case ROLES.HR:
    //         return '/admin/dashboard';
    //       case ROLES.CANDIDATE:
    //         return '/hr/manager/dashboard';
    //       case ROLES.HR_RECRUITER:
    //         return '/hr/recruiter/dashboard';
    //       case ROLES.EMPLOYEE:
    //         return '/employee/dashboard';
    //       case ROLES.CANDIDATE:
    //         return '/candidate/dashboard'; // If applicable
    //       default:
    //         return '/login'; // Or another default route if not authenticated
    //     }
    //   }

    if(window.screen.width <= 850 && (
        window.location.pathname?.includes("interview") || 
        window.location.pathname?.includes("auth") || 
        window.location.pathname?.includes("dashboard")
    )){
        return (
              <div className="h-screen bg-primary justify-center items-center flex flex-col px-2 text-base font-normal text-center gap-4">
                <p className="text-white">This website is best viewed on a desktop.</p>
                <p className="text-white">Please switch to a larger device for the best experience.</p>
            </div>
        )
    }

    else if(loading) {
        return (
            <div className="h-screen w-screen flex justify-center items-center">
                <LoadingComponent />
            </div>
        )
    }

    return (
        <Routes>
            <Route path="/" element={<Layout />}>
                <Route path="" element={<LandingPage />} />
                <Route path="/products" element={<ProductsPage />} />
                {/* <Route path="/pricing" element={<PricingPage />} /> */}
                <Route path="/solution" element={<SolutionPage />} />
                <Route path="/about-us" element={<AboutUsPage />} />
                <Route path="/schedule" element={<BookDemoPage />} />
                <Route path="/try-for-free" element={<TrialRequestPage />} />
                <Route path="*" element={<LandingPage />} />
            </Route>
            <Route path='/interview/*' element={<InterviewRoutes />} />
            <Route path="/auth/*" element={!isAuthenticate ? <AuthRoutes /> : <Navigate to={'/dashboard'} />}/>
            <Route path='/dashboard/*' element={isAuthenticate && userRole === ROLES.HR ? <HRRoutes /> : <Navigate to="/auth/login"/>}/>
            <Route path="/notfound" element={<NotFound />} />
            <Route path="/sitemap.xml" element={<Sitemap />} />
        </Routes>
    )
}
