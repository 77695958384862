import { Route, Routes } from "react-router-dom";
import HRUserManagement from "../pages/HR/UserManagement/HRUserManagement";
import HRUserManagementCreate from "../pages/HR/UserManagement/HRUserManagementCreate";

export default function HRUserManagementRoutes(){
    return (
        <Routes>
            <Route index path="" element={<HRUserManagement />} />
            <Route path="create" element={<HRUserManagementCreate />} />
        </Routes>
    )
}