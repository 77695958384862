import { useState } from "react";
import InterviewExitPopup from "./InterviewExitPopup";
import { RESUME_STAGES } from "../../utils/constants";
import { useSessionContext } from "../../contextProviders/SessionContext";
import { useMediaContext } from "../../contextProviders/InterviewMediaContext";

export default function InterviewRoomFooter(props){
    const {stopRecording, onExit} = props;
    const {sessionInfo} = useSessionContext();
    const [showExitPopup, setShowExitPopup] = useState(false);
    const [isMicMuted, setIsMicMuted] = useState(false);
    const {audioStream, setAudioStream} = useMediaContext();


    const toggleMute = () => {
        if (audioStream) {
            setIsMicMuted(prevMuted => {
                const newMutedState = !prevMuted;
                setAudioStream(prevStream => {
                    if (prevStream) {
                        const newStream = prevStream.clone();
                        const audioTracks = newStream.getAudioTracks();
                        audioTracks.forEach(track => {
                            track.enabled = newMutedState;
                        });
                        return newStream;
                    }
                    return prevStream;
                });
    
                return newMutedState;
            });     
        }
    };

    return (
        <div className="z-20 flex justify-center text-white text-xs 2xl:text-sm font-medium items-center bg-[#1F3044]">
            <div className="md:w-[90%] flex justify-center items-center h-16 2xl:h-20 px-5">
                <div className="flex gap-5 w-4/5 flex-1 items-center">
                    <div className="flex gap-2 items-center">
                        <span>{
                            sessionInfo.interview_type === RESUME_STAGES.INITIAL_SCREENING ? 'Initial Screening': 
                            sessionInfo.interview_type === RESUME_STAGES.TECHNICAL_SCREENING ? 'Technical Screening': 'OPS Interview'
                        }
                        </span>
                        <span>|</span>
                        <p>JOB TITLE : <span className="text-blue font-semibold">{sessionInfo?.job_title}</span></p>
                    </div>
                    {/* <span className={`${isConnected ? 'text-dg' : 'text-do'} text-base`}>{isConnected ? 'Connected' : 'Connecting . . .'}</span> */}
                </div>
                {/* <div className="h-9 w-9 2xl:h-11 2xl:w-11 flex justify-center items-center bg-white rounded-full text-primary cursor-pointer">
                    <MdMicNone
                        size={window.screen.width > 1500 ? 24 : 18} 
                        onClick={toggleMute}
                        className={`${isMicMuted ? 'hidden': 'visible'}`}
                    />
                    <MdMicOff
                        size={window.screen.width > 1500 ? 24 : 18}
                        onClick={toggleMute}  
                        className={`${!isMicMuted ? 'hidden': 'visible'}`}
                    />
                </div> */}
                <div className="flex-1 justify-end flex">
                    <button
                        onClick={() => setShowExitPopup(true)} 
                        className="bg-dr 2xl:h-10 2xl:px-10 px-7 h-8 rounded">
                        Exit Interview
                    </button>
                </div>
                {/* <div className="flex gap-10 w-1/5 flex-1 justify-end">
                    <IoMdHelpCircleOutline 
                        size={24}
                        className="cursor-pointer"
                    />
                    <HiOutlineUsers 
                        size={24}
                        className="cursor-pointer"
                    />
                    <MdOutlineMessage 
                        size={24}
                        className="cursor-pointer"
                        onClick={toggleTranscriptView}
                    />
                </div> */}
                <InterviewExitPopup
                    onExit={onExit}
                    visible={showExitPopup} 
                    stopRecording={stopRecording}
                    cancel={() => setShowExitPopup(false)}
                />
            </div>
        </div>
    )
}