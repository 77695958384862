import { useEffect } from 'react';
import { useSessionContext } from '../../contextProviders/SessionContext';
import { useAnomalies } from '../../contextProviders/AnomaliesContext';

function TabSwitchDetector() {
  const {state} = useSessionContext();
  const { anomaliesData, updateAnomalies } = useAnomalies();

  useEffect(() => {
    const handleVisibilityChange = () => {
      console.log('handleVisibilityChange', document.hidden, state)
      if (document.hidden && state === 'AUTHORISED') {
        updateAnomalies({ 
          tab_switches_count: anomaliesData.tab_switches_count += 1
        });
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [anomaliesData.tab_switches_count, updateAnomalies]);

  return null;
}

export default TabSwitchDetector;