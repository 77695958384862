import propTypes from 'prop-types';
import React, { useRef, useEffect, useState } from 'react';
import { useMediaContext } from '../../contextProviders/InterviewMediaContext';
import { useAnomalies } from '../../contextProviders/AnomaliesContext';
import FaceDetection from '../../utils/face-detection';

const VideoPreview = (props) => {
  const videoRef = useRef(null);
  const [videoReady, setVideoReady] = useState(false);
  let {videoStream, setVideoStream} = useMediaContext();
  const [faceDetected, setFaceDetected] = useState(false);
  const [modelsLoaded, setModelsLoaded] = useState(false);
  const { anomaliesData, updateAnomalies } = useAnomalies();

  useEffect(() => {
    async function init(){
      await FaceDetection.loadModels();
      setModelsLoaded(true);
      if(!videoStream){
        videoStream = await navigator.mediaDevices.getUserMedia({ 
          video: true, 
          audio: false 
        });
        setVideoStream(videoStream);
      }

      if(videoRef.current){
        videoRef.current.srcObject = videoStream;
      }
    }
    init();
}, [videoStream]);


  // useEffect(() => {
  //   if (videoReady && modelsLoaded) {
  //     const detectFaces = setInterval(async () => {
  //       if (videoRef.current) {
  //         const video = videoRef.current;

  //         const displaySize = { width: video.videoWidth, height: video.videoHeight };
  //         const fullFaceDetections = await FaceDetection.getFullFaceDetections(video, displaySize);
  //         console.log('fullFaceDetections.length > 0', fullFaceDetections.length > 0)
  //         if (fullFaceDetections.length > 0) {
  //           setFaceDetected(true);
            
  //         } else {
  //           updateAnomalies({ 
  //             face_missing: anomaliesData.face_missing += 1
  //           });
  //           setFaceDetected(false);
  //         }
  //       }
  //     }, 1000);

  //     return () => clearInterval(detectFaces);
  //   }
  // }, [videoReady, modelsLoaded]);

  const handleVideoOnPlay = () => {
    setVideoReady(true);
  }

  return (
    <div className="relative h-full overflow-hidden">
      <div className='absolute left-5 top-5 flex bg-white text-xs 2xl:text-sm h-9 2xl:h-10 rounded-full items-center justify-center px-5'>
        {!props.isRecording && <span className="text-gray-900">Recording Off</span>}
        {props.isRecording && (
          <div className='flex gap-2 justify-center items-center'>
            <div className='h-4 w-4 rounded-full bg-dr'></div>
            <span className="">Recording On</span>
          </div>
        )}
      </div>
      <video 
          muted 
          autoPlay 
          ref={videoRef} 
          disablePictureInPicture
          onPlay={handleVideoOnPlay} 
          className='h-full aspect-video rounded-2xl bg-black'
      />
  </div>
  );
}

VideoPreview.propTypes = {
  isRecording: propTypes.bool
}

export default React.memo(VideoPreview);