import { useNavigate } from "react-router-dom";
import LogService from "../../services/log.service";
import { isSafariBrowser } from "../../utils/common";
import { RESUME_STAGES } from "../../utils/constants";
import CommonService from "../../services/common.service";
import React, { useEffect, useRef, useState } from "react";
import SessionService from "../../services/session.service";
import VideoPreview from "../../components/Common/VideoPreview";
import InterviewService from "../../services/interview.service";
import BotComponent from "../../components/Interview/BotComponent";
import * as SpeechSDK from 'microsoft-cognitiveservices-speech-sdk';
import backgroundImage1 from './../../assets/images/background1.svg';
import backgroundImage2 from './../../assets/images/background2.svg';
import { useAnomalies } from "../../contextProviders/AnomaliesContext";
import { useSessionContext } from "../../contextProviders/SessionContext";
import TabSwitchDetector from "../../components/Interview/TabSwitchDetector";
import { useMediaContext } from "../../contextProviders/InterviewMediaContext";
import InterviewRoomFooter from "../../components/Interview/InterviewRoomFooter";
import InterviewRoomHeader from "../../components/Interview/InterviewRoomHeader";
import RecordingUploadComponent from "../../components/Common/RecordingUploadComponent";
import MultiMonitorDetectedPopup from "../../components/Common/MultiMonitorDetectedPopup";
import InterviewPermissionDialog from "../../components/Interview/InterviewPermissionDialog";

const CHUNK_DURATION = 20;

const InterviewRoom = () => {
    const chunks = useRef([]);
    const navigate = useNavigate();
    const chunkIntervalRef = useRef(null);
    
    const {sessionInfo} = useSessionContext();
    const tokenRefreshIntervalRef = useRef(null);
    const [analyser, setAnalyser] = useState(null);
    const [dataArray, setDataArray] = useState(null);
    const [recognizer, setRecognizer] = useState(null);
    const [isSpeaking, setIsSpeaking] = useState(false);
    const [isConnected, setIsConnected] = useState(false);
    const [isUploading, setIsUploading] = useState(false);
    const [hideTranscript, setHideTranscript] = useState(false);
    const [isInterviewCompleted, setIsInterviewCompleted] = useState(false);

    const [recognizedText, setRecognizedText] = useState('');
    const [mediaRecorder, setMediaRecorder] = useState(null);
    const [listenToCandidate, setListenToCandidate] = useState(false);
    const [isConnectionIssue, setIsConnectionIssue] = useState(false);
    const [showFacedDetectionPopup, setShowFacedDetectionPopup] = useState(false);

    const token = sessionInfo?.token || localStorage.getItem('sessionToken');

    let {
        displayStream, 
        audioStream, 
        videoStream, 
        setAudioStream,
        setDisplayStream,
        setVideoStream
    } = useMediaContext();

    const {
        setState,
        connection,
        isRecording,
        isBotSpeaking,
        setConnection,
        setIsRecording,
        clearTranscripts,
        clearMessageQueue,
        handleBotMessages,
        addMessageToTranscript,
        getLastQuestionId,
    } = useSessionContext();

    const {anomaliesData, uploadAnomalies} = useAnomalies();

    const toggleTranscriptView = () => setHideTranscript(!hideTranscript);

    const audioContext = new (window.AudioContext || window.webkitAudioContext)({
        sampleRate: 48000,
    });

    const isPermissionsMissing = () => {
        if(!audioStream || !displayStream || !videoStream)
            return true;
        return false;
    }

    const isStreamsActive = () => {
        if(!audioStream?.active || !displayStream?.active || !videoStream?.active)
            return false;
        return true;
    }
    
    const initializeRecognizer = async () => {
        let responseData = null;
        try {
            const response = await CommonService.getSpeechToken(token);
            responseData = response.data;
        } catch (error) {
            navigate(-1);
        }

        const speechConfig = SpeechSDK.SpeechConfig.fromAuthorizationToken(responseData?.token, responseData?.region);
        speechConfig.speechRecognitionLanguage = 'en-IN';

        const audioConfig = SpeechSDK.AudioConfig.fromStreamInput(audioStream);
        
        const recognizer = new SpeechSDK.SpeechRecognizer(speechConfig, audioConfig);
    
        recognizer.recognized = (s, e) => {
        // LogService.uploadLog(token, sessionInfo?.session_id, `recognizer.recognized ${e.result.reason} ${e.result.text}`);
          if (e.result.reason === SpeechSDK.ResultReason.RecognizedSpeech && !isBotSpeaking) {
            if(e.result.text?.trim() !== 'Play.' && e.result.text.trim() !== 'BJP.')
                setRecognizedText(prevText => prevText + ' ' + e.result.text);
          }
          else if (e.result.reason === SpeechSDK.ResultReason.NoMatch) {
            console.log("NOMATCH: Speech could not be recognized.");
          }
        };
    
        setRecognizer(recognizer);
        startListening(recognizer);
    };

    useEffect(() => {
        if(anomaliesData.face_missing !== 0 && anomaliesData.face_missing % 10 === 0){
            setShowFacedDetectionPopup(true)
        }
    }, [anomaliesData.face_missing]);


    useEffect(() => {
        const startRecognition = async () => {
            await initializeRecognizer();
        };

        if (isConnected){
            startRecognition();

            // Refresh token every 10 minutes
            tokenRefreshIntervalRef.current = setInterval(async () => {
                const response = await CommonService.getSpeechToken(token);
                if(response.status === 200){
                    const {token} = response.data;
                    setRecognizer((prevMyState) => {
                        if(token && prevMyState ) prevMyState.authorizationToken = token ;
                        return prevMyState; 
                    });
                }
            }, 9 * 60 * 1000);
        }
    }, [isConnected]);

    useEffect(() => {
        let TIMEOUT = null;
        if((isPermissionsMissing()  || !sessionInfo?.token) && !isUploading){
            TIMEOUT = setTimeout(() => {
                if(isPermissionsMissing() || !isStreamsActive() || !sessionInfo?.token){
                    navigate(-1);
                }
            }, 5000);
        }
        
        async function initiateConnection(){
            LogService.uploadLog(token, sessionInfo?.session_id, 'initiateConnection');
            let websocketConnection = connection;
            if(!websocketConnection){
                if(sessionInfo?.companyName === "alorica"){
                    if(sessionInfo.interview_type === RESUME_STAGES.INITIAL_SCREENING)
                        websocketConnection = InterviewService.connectToBPOInitialInterviewBot(sessionInfo?.token || token);
                    else if(sessionInfo.interview_type === RESUME_STAGES.OPS_SCREENING)
                        websocketConnection = InterviewService.connectToBPOTechnicalInterviewBot(sessionInfo?.token || token);
                }
                else {
                    if(sessionInfo.interview_type === RESUME_STAGES.INITIAL_SCREENING)
                        websocketConnection = InterviewService.connectToInitialInterviewBot(sessionInfo?.token || token);
                    else if(sessionInfo.interview_type === RESUME_STAGES.TECHNICAL_SCREENING)
                        websocketConnection = InterviewService.connectToTechnicalInterviewBot(sessionInfo?.token || token);
                }
                setConnection(websocketConnection)
            }
        
            websocketConnection.onopen = () => {
                LogService.uploadLog(token, sessionInfo?.session_id, 'websocketConnection open');
                setIsConnected(true);
                clearTranscripts();
                setIsConnectionIssue(false);
            };
        
            websocketConnection.onmessage = async (event) => {
                const data = JSON.parse(event.data);
                const {result, audio_stream, questionID, is_completed, remaining_time, type} = data;
                LogService.uploadLog(token, sessionInfo?.session_id, `websocketConnection onmessage type:${type} resume:${result} questionID:${questionID} is_completed:${is_completed}`);

                // setListenToCandidate(true);

                if(is_completed) setIsInterviewCompleted(true);
                
                setRecognizedText('');
                if(result && audio_stream){
                    handleBotMessages({
                        questionID,
                        is_completed,
                        remaining_time,
                        message: result,
                        audio_stream,
                        sender: 'Athmiya',
                        type
                    });
                }
                else if (type === 'RECEIVED_CANDIDATE_TEXT' && audio_stream){
                    handleBotMessages({audio_stream, sender: 'Athmiya'});
                }
            };

            websocketConnection.onerror = () => {
                LogService.uploadLog(token, sessionInfo?.session_id, `websocketConnection onerror`);
                setIsConnectionIssue(true);
                stopRecording();
                stopListening();
            }
        
            websocketConnection.onclose = async () => {
                LogService.uploadLog(token, sessionInfo?.session_id, `websocketConnection onclose`);
                setIsConnected(false);
                stopListening();
                stopRecording();
            };
            
        }

        if(!isPermissionsMissing() && isStreamsActive()){
            startRecording();
            initiateConnection();
        }
    
        return () => {
            clearTimeout(TIMEOUT);
            if(connection) connection.close();
        };
    }, [sessionInfo?.token, audioStream, videoStream, displayStream]);

    useEffect(() => {

        const timeout = setTimeout(() => {
            if(!isConnected && connection)
                setIsConnectionIssue(true)
        }, 10000);

        return () => clearTimeout(timeout);
    }, [isConnected, connection])

    useEffect(() => {
        const getUserMedia = async () => {
            try {
                const stream = audioStream;

                const analyserNode = audioContext.createAnalyser();
                analyserNode.fftSize = 2048;
                const source = audioContext.createMediaStreamSource(stream);
                source.connect(analyserNode);

                const bufferLength = analyserNode.frequencyBinCount;
                const dataArray = new Uint8Array(bufferLength);
                setAnalyser(analyserNode);
                setDataArray(dataArray);
            } 
            catch (error) {
                console.error('Error accessing microphone', error);
            }
        };

        getUserMedia();
    }, []);
    
    useEffect(() => {
        let speakingTimeout = null;
        const TIMEOUT = sessionInfo?.companyName === "alorica" ? 1000 : 2500;
    
        const detectSpeech = () => {
          if (analyser && dataArray) {
            analyser.getByteFrequencyData(dataArray);
            const sum = dataArray.reduce((a, b) => a + b, 0);
            const average = sum / dataArray.length;
    
            if (average > 20) {
              setIsSpeaking(true);
              if (speakingTimeout) clearTimeout(speakingTimeout);
              speakingTimeout = setTimeout(() => {
                setIsSpeaking(false);
              }, TIMEOUT);
            }
          }
    
          requestAnimationFrame(detectSpeech);
        };
    
        detectSpeech();
    
        return () => {
          if (speakingTimeout) clearTimeout(speakingTimeout);
        };
    }, [analyser, dataArray]);

    useEffect(() => {
        if(recognizedText != null && recognizedText !== '' && 
                recognizedText?.trim()?.length > 0){
            if(connection && !isSpeaking && !isBotSpeaking){
                // setListenToCandidate(false);
                addMessageToTranscript({
                    sender: 'You',
                    message: recognizedText,
                    questionID: getLastQuestionId(),
                })
                const requestData = JSON.stringify({
                    interviewState: 1,
                    questionID: getLastQuestionId(),
                    type: "CANDIDATE_RESPONSE",
                    candidateText: recognizedText,
                });
                setRecognizedText('');
                isMicOn(false);
                connection.send(requestData);
                LogService.uploadLog(token, sessionInfo?.session_id, `sent candidate answer questionID: ${getLastQuestionId()} candidateText: ${recognizedText}`);
            }
        }
    }, [recognizedText, isSpeaking, isBotSpeaking]);

    const createMediaRecorder = () => {
        if (typeof MediaRecorder === 'undefined') {
            console.error('MediaRecorder is not supported in this browser');
            return;
        }

        try {
            if (!displayStream || !audioStream) {
                console.log('One or more required streams are not available');
                return;
            }
            const combinedStream = new MediaStream([
                ...displayStream?.getTracks(),
                ...audioStream.getTracks(),
            ]);
            
            const mediaRecorder = new MediaRecorder(combinedStream, { 
                mimeType: isSafariBrowser() ? 'video/mp4;codecs:h264': 'video/mp4; codecs=vp9',
                audioBitsPerSecond: 128000,
                videoBitsPerSecond: 2500000,
            });
            mediaRecorder.ondataavailable = handleDataAvailable;
            mediaRecorder.start();
        
            setTimeout(() => {
              mediaRecorder.stop();
            }, CHUNK_DURATION * 1000);

            setMediaRecorder(mediaRecorder);
        } catch (error) {
            console.log(error);
        }
    };

    const handleDataAvailable = (event) => {
        if (event.data.size > 0){
            const blob = new Blob([event.data], { type: 'video/mp4' });
            uploadSessionRecording(blob);
        }
    };

    const startListening = async (recognizer) => {
        LogService.uploadLog(token, sessionInfo?.session_id, 'startListening');
        if (recognizer) {
            try {
                await recognizer.startContinuousRecognitionAsync();
                console.log("Recognition started");
            } catch (err) {
                console.error("Error starting recognition:", err);
            }
        }
      };
    
    const stopListening = async () => {
        LogService.uploadLog(token, sessionInfo?.session_id, 'stopListening');
        if (recognizer) {
            try {
                recognizer.close();
                await recognizer.stopContinuousRecognitionAsync();
                if (tokenRefreshIntervalRef.current)
                    clearInterval(tokenRefreshIntervalRef.current);
                
                console.log("Recognition stopped");
            } catch (err) {
                LogService.uploadLog(token, sessionInfo?.session_id, 'Error stopping recognition');
                console.error("Error stopping recognition:", err);
            }
        }
    };

    const isMicOn = (state = true) => {
        const audioTracks = audioStream?.getAudioTracks();
            audioTracks?.forEach(track => {
                track.enabled = state;
        });     
    }

    const startRecording = () => {
        try {
            LogService.uploadLog(token, sessionInfo?.session_id, 'startRecording');
            createMediaRecorder();
            chunkIntervalRef.current = setInterval(() => {
                createMediaRecorder();
            }, CHUNK_DURATION * 1000);
            setIsRecording(true)
        } catch (error) {
            LogService.uploadLog(token, sessionInfo?.session_id, 'startRecording error');
            console.log(error);
        }
    };

    const stopRecording = () => {
        if(chunkIntervalRef.current)
            clearInterval(chunkIntervalRef.current)

        if(displayStream && displayStream.active)
            displayStream.getTracks().forEach(track => track.stop());

        if(videoStream && videoStream.active)
            videoStream.getTracks().forEach(track => track.stop());

        if(audioStream && audioStream.active)
            audioStream.getTracks().forEach(track => track.stop());

        setAudioStream(null);
        setVideoStream(null);
        setDisplayStream(null);
        setIsRecording(false);
    }

    const uploadSessionRecording = async (blob) => {
        const fileName = `${Date.now()}.mp4`;
        const data = {
            fileName,
            file: blob,
            token: sessionInfo.token,
            duration: CHUNK_DURATION,
            sessionId: sessionInfo?.session_id,
        }
        chunks.current.push(data);
        try {
            await SessionService.recordedFileUpload(data);
            chunks.current.shift();
        } catch (error) {
            LogService.uploadLog(token, sessionInfo?.session_id, 'Error uploading chunk');
            console.error('Error uploading chunk:', error);
        }
    };

    // useEffect(() => {
    //     return () => {
    //         stopListening();
    //         stopRecording();
    //         setConnection(null);
    //         clearTranscripts();
    //         clearMessageQueue();
    //     };
    // }, []);


    const stopAllStreams = () => {
        audioStream.getTracks().forEach((track) => track.stop());
        displayStream.getTracks().forEach((track) => track.stop())
        videoStream.getTracks().forEach((track) => track.stop())
    }

    const onExit = async () => {
        LogService.uploadLog(token, sessionInfo?.session_id, 'onExit');
        setIsUploading(true);        
        try {
            if (mediaRecorder && mediaRecorder.state !== "inactive")
                mediaRecorder.stop();
            
            stopAllStreams();
            stopRecording();
            stopListening();
            clearTranscripts();
            clearMessageQueue();
            await uploadAnomalies();

            LogService.uploadLog(token, sessionInfo?.session_id, `chunks length ${chunks.current.length}`);
      
            if (chunks.current.length > 0) {
                for (const chunk of chunks.current)
                    await SessionService.recordedFileUpload(chunk);
                
                if (connection && connection.readyState === WebSocket.OPEN) {
                    const requestData = JSON.stringify({ interviewState: 0 });
                    connection.send(requestData);
                }
                setConnection(null);
                setIsUploading(false);
                setState('EXITED');
            }
            else {
                setConnection(null);
                setIsUploading(false);
                setState('EXITED');
            }
        } catch (error) {
            setIsUploading(false);
            LogService.uploadLog(token, sessionInfo?.session_id, 'Error during exit process');
            console.error('Error during exit process:', error);
        }
    };

    const onCompleted = async () => {
        LogService.uploadLog(token, sessionInfo?.session_id, 'onCompleted');
        setIsUploading(true);        
        try {
            if (mediaRecorder && mediaRecorder.state !== "inactive")
                mediaRecorder.stop();
            
            stopAllStreams();
            stopRecording();
            stopListening();
            setConnection(null);
            clearTranscripts();
            clearMessageQueue();
            await uploadAnomalies();
        
            LogService.uploadLog(token, sessionInfo?.session_id, `chunks length ${chunks.current.length}`);
            if (chunks.current.length > 0) {
                for (const chunk of chunks.current)
                    await SessionService.recordedFileUpload(chunk);
                setIsUploading(false);
                setState('COMPLETED')
            }
            else {
                setIsUploading(false);
                setState('COMPLETED')
            } 
        } catch (error) {
            setIsUploading(false);
            LogService.uploadLog(token, sessionInfo?.session_id, 'Error during completed process');
            console.error('Error during completed process:', error);
        }
    };

    return (
        <div className="bg-primary h-screen w-screen flex flex-col gap-2 3xl:gap-5">
            {isInterviewCompleted === false && <InterviewPermissionDialog />}
            <MultiMonitorDetectedPopup />
            <TabSwitchDetector />
            <RecordingUploadComponent visible={isUploading}/>
            <div className={`${isConnectionIssue ? 'visible': 'hidden'} absolute top-0 left-0 h-screen w-screen bg-transparent z-10 flex justify-center items-center`}>
                <div className='bg-white p-5 rounded-md shadow-none flex flex-col gap-3 justify-center items-center'>
                    <h2 className='font-bold 3xl:text-xl'>Connection Problem</h2>
                    <p className="text-sm">We're unable to reach the server at the moment. Please try again later.</p>
                    <button
                        onClick={() => {
                            navigate(-1)
                            stopListening();
                            stopRecording();
                        }}
                        className='bg-primary w-40 mt-4 text-white h-9 3x:h-10 rounded-md text-sm 3x:text-sm'>Exit</button>
                </div>
            </div>
            <img
                alt="background" 
                src={backgroundImage1}
                className="absolute bottom-0 right-0"
            />
            <img
                alt="background" 
                src={backgroundImage2}
                className="absolute top-40 left-0"
            />
            <InterviewRoomHeader isRecording={isRecording}/>
            <div className="px-5 flex-1 flex 3xl:gap-5 m-auto overflow-hidden 3xl:h-[90%] 3xl:w-[90%] justify-center items-center">
                <div className={`relative flex h-full w-full ${hideTranscript ? 'w-full': 'w-4/5'} rounded-lg flex-col items-center`}>
                    <VideoPreview isRecording={isRecording}/>
                    <div className="absolute bottom-10 right-10">
                        <BotComponent 
                            isConnected={isConnected}
                            onCompleted={onCompleted}
                            stopRecording={stopRecording} 
                            isConnectionIssue={isConnectionIssue}
                            isAloricaFlow={sessionInfo?.companyName === "alorica"}
                        />
                    </div>
                </div>
                {/* <div className={`w-1/5 bg-white rounded-lg h-full flex-col items-center overflow-y-scroll ${hideTranscript ? 'hidden': 'flex'}`}>
                    <TranscriptComponent
                        isSpeaking={isSpeaking}
                        isConnected={isConnected}
                        // isMessageSent={isMessageSent}
                        // isDisconnected={isDisconnected}
                        hideTranscript={hideTranscript}
                        toggleTranscriptView={toggleTranscriptView}
                    />
                </div> */}
            </div>
            {/* <div className={`h-screen w-screen bg-transparent absolute left-0 top-0 z-40 justify-center items-center flex ${showFacedDetectionPopup ? 'visible': 'hidden'}`}>
                <div className='bg-white p-5 rounded-md'>
                    <h2 className='text-primary font-bold text-base 3xl:text-xl'>Face Not Detected</h2>
                    <div className='pt-3 3xl:pt-5 font-normal flex flex-col gap-4 mt-2 justify-center items-center'>
                        <p className='text-sm 3xl:text-base'>Please note, if the face is not visible, the interview will be invalidated</p>
                        <button
                            onClick={() => setShowFacedDetectionPopup(false)} 
                            className='h-9 3xl:h-10 bg-blue text-white w-20 rounded-md font-semibold text-xs 3xl:text-sm'>
                            Close
                        </button>
                    </div>
                </div>
            </div> */}
            <InterviewRoomFooter 
                onExit={onExit}
                isConnected={isConnected}
                stopRecording={stopRecording}
                toggleTranscriptView= {toggleTranscriptView}
            />
        </div>
    )
}

export default React.memo(InterviewRoom);
