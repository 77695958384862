import { useNavigate } from "react-router-dom";
import { MdKeyboardArrowLeft } from "react-icons/md";
import { IoIosHelpCircleOutline } from "react-icons/io";
import {InputText} from 'primereact/inputtext'

export default function HRUserManagementCreate(){
    const navigate = useNavigate();

    const goBack = () => {
        navigate(-1)
    }
    
    return (
        <div className="flex flex-col gap-10 rounded-md">
            <div className="flex flex-row items-center justify-between w-full">
                <div 
                    onClick={goBack}
                    className="flex items-center gap-1 text-[#161616] cursor-pointer">
                    <MdKeyboardArrowLeft size={26}/>
                    <h1 className='text-xl text-primary font-bold'>Create HR Login</h1>
                </div>
                <div className='flex gap-6 text-brownGray'>
                    <div className="bg-white h-10 w-10 rounded-full flex justify-center items-center cursor-pointer">
                        <IoIosHelpCircleOutline 
                            size={26}
                            color="#666666"
                        />
                    </div>
                </div>
            </div>
            <div className="bg-white rounded-md p-10 flex gap-10 text-[#999999]">
                <div className="flex flex-1 flex-col gap-4">
                    <h3 className="text-sm text-darkGray">Organisation Id</h3>
                    <InputText
                        disabled
                        defaultValue="Athmick@42324099" 
                        className="h-12 bg-[#F6F7F9] rounded-md px-2 text-sm"
                    />
                </div>
                <div className="flex flex-1 flex-col gap-4">
                    <h3 className="text-sm text-darkGray">Organisation Name</h3>
                    <InputText 
                        disabled
                        defaultValue="Athmick" 
                        className="h-12 bg-[#F6F7F9] rounded-md px-2 text-sm"
                    />
                </div>
            </div>
            <div className="bg-white rounded-md p-10 flex gap-10 text-[#999999] flex-col">
                <div className="flex gap-10">
                    <div className="flex w-1/3 flex-col gap-4">
                        <h3 className="text-sm text-darkGray">Name</h3>
                        <InputText
                            className="h-12 border border-[#EAECF0] rounded-md px-2 text-sm"
                        />
                    </div>
                    <div className="flex w-1/3 flex-col gap-4">
                        <h3 className="text-sm text-darkGray">Role</h3>
                        <InputText 
                            className="h-12 border border-[#EAECF0] rounded-md px-2 text-sm"
                        />
                    </div>
                    <div className="flex w-1/3 flex-col gap-4">
                        <h3 className="text-sm text-darkGray">Email</h3>
                        <InputText 
                            className="h-12 border border-[#EAECF0] rounded-md px-2 text-sm"
                        />
                    </div>
                </div>
                <div className="flex gap-10">
                    <div className="flex w-1/3 flex-col gap-4">
                        <h3 className="text-sm text-darkGray">Mobile Number</h3>
                        <InputText
                            className="h-12 border border-[#EAECF0] rounded-md px-2 text-sm"
                        />
                    </div>
                    <div className="flex w-1/3 flex-col gap-4">
                        <h3 className="text-sm text-darkGray">Password</h3>
                        <InputText
                            className="h-12 border border-[#EAECF0] rounded-md px-2 text-sm"
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}